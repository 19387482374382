.expand-link {
  &:hover {
    .icon-expand {
      display: none;
    }
    .icon-contract {
      display: block;
    }
  }
  &:active {
    .icon-expand {
      display: none;
    }
    .icon-contract {
      display: block;
    }
  }
  .icon-contract {
    display: none;
  }
}

.verification-selection-control-container {
  display: flex;
  align-items: center;
  font-size: $font_size_sm;
  color: $grey_dark;
  font-weight: 400;
  font-family: $Inter_Regular;

  &-disabled > * {
    opacity: 0.5;
    pointer-events: none;
  }

  &.selection-made .Dropdown-control {
    color: $blue_icon;
  }
}

@media print {
  .verification-selection-control-container:not(.selection-made) {
    display: none;
  }
}

button.verify-button,
.verify-button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  align-self: auto;

  &:focus {
    outline: none;
  }
}

.dropdown-menu-class:hover {
  color: $blue_icon;
}
