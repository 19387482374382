.awards-education {
  font-size: $font_size_sm;
  color: $grey_mid;
  font-family: $Inter_SemiBold;

  .toggle-icon {
    &__extra-information {
      width: 16px;
      height: 16px;

      &--expanded {
        transform: rotate(90deg);
      }
    }
  }
  .flex-title-row {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: baseline;
  }

  .inline-block {
    display: inline-block !important ;
  }
  .small-right-padding {
    padding-right: 8px;
  }

  &--title {
    font-family: $InterDisplay;
  }

  .logo-column {
    max-width: 86px;
  }
  .title-column {
    max-width: 355px;
  }
  .description-column {
    max-width: 388px;

    .description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
  .ranking-column {
    max-width: 203px;
  }
  .establishment-logo {
    max-width: 72px;
    max-height: 52px;
    margin: 0;
    padding: 0;
  }

  .award-education-section:last-child {
    margin-bottom: 1px;
  }
}
