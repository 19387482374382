.LocationsBarContainer_l1rownum{padding-left:32px;-webkit-flex:1;-ms-flex:1;flex:1;max-height:170px;overflow-y:auto;}
.CollapseArrow_c16bw8dt{width:16px;height:10px;-webkit-transform:var(--c16bw8dt-0);-ms-transform:var(--c16bw8dt-0);transform:var(--c16bw8dt-0);}
.European_ehfy94f{width:20px;height:20px;margin-right:4px;}.European_ehfy94f path{fill:#ef7273;}
.Transparency_tysf02h{width:18px;height:18px;margin-right:4px;margin-top:1px;}.Transparency_tysf02h path{fill:#ef7273;}
.Treasury_ti17gu1{width:20px;height:20px;margin-right:4px;}
.CheckboxAndLabel_c1orw47x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:var(--c1orw47x-0);}
.Bar_b9fztrb{width:100%;background-color:#858996;margin:4px 0px;font-size:0.625rem;padding:0 4px;color:#ffffff;margin-right:1px;}
.SelectedBar_s13ekgja.Bar_b9fztrb{background-color:var(--s13ekgja-0);border-radius:var(--s13ekgja-1);width:var(--s13ekgja-2);}
.DefaultSelectedBar_dfxb2r2.Bar_b9fztrb{border-radius:var(--dfxb2r2-0);width:var(--dfxb2r2-1);background-color:var(--dfxb2r2-2);}
.InactiveBar_i17fzcw8.Bar_b9fztrb{background-color:var(--i17fzcw8-0);border-radius:var(--i17fzcw8-1);width:var(--i17fzcw8-2);}
.CountryBarInnerWrapper_c1fl85pw{width:var(--c1fl85pw-0);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border-radius:2px;}
.CountryBarOuterWrapper_cp9plug{width:50%;cursor:var(--cp9plug-0);text-align:var(--cp9plug-1);}
.RiskFlagsContainer_rk3z78q{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-right:4px;}
.Label_lfr05j8{margin-bottom:0;cursor:var(--lfr05j8-0);color:var(--lfr05j8-1) !important;}.Label_lfr05j8:before{border:var(--lfr05j8-2) !important;background:var(--lfr05j8-3) !important;}
.LabelWrapper_lvzv9cb{cursor:var(--lvzv9cb-0);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-left:var(--lvzv9cb-1);width:var(--lvzv9cb-2);}
