.SourceListContainer_s148uw7a{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.Options_o1gac3qr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;font-size:0.875rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#858996;padding:8px;}
.OpenAll_o1xrwykd{all:unset;font-size:0.875rem;color:#858996;}.OpenAll_o1xrwykd:focus,.OpenAll_o1xrwykd:hover{outline:none;color:#00a8e5;}
.NumberSources_ntm4ik3{font-size:0.875rem;color:#858996;}
.SourceItemsContainer_s1djdy0b{height:100%;overflow:auto;}
.GlobeIcon_g16na6j4{width:30px;height:30px;}
.CorporateRecordIcon_c12m1pz1{width:30px;height:30px;}
.ScreeningProviderIcon_syunplf{width:30px;height:30px;}
.LinkedInIcon_l1o4e5j4{width:30px;height:30px;}
.TwitterIcon_t6ts95v{width:30px;height:30px;}
.DJIcon_d1lm8ft8{width:30px;height:30px;}
.CommercialAggregatorIcon_c16drmwd{width:30px;height:30px;}
.PublisherLogo_pk9i6s3{height:64px;width:64px;}
.IconWrapper_igswhsq > svg path{fill:var(--igswhsq-0);}
.SourceDate_s1trdx5w{font-size:0.875rem;color:#858996;text-align:left;margin-left:8px;}
.DurationSincePublished_dqkluzs{color:#858996;}
.Pagination_p7aepv3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;}
.PaginationButton_p16va6ot{all:unset;cursor:pointer;}.PaginationButton_p16va6ot:disabled{cursor:not-allowed;opacity:0.5;}.PaginationButton_p16va6ot:focus{outline:none;}
.CurrentPage_c1vzzcs8{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
