.CardInnerContainer_c1hslh2c{width:100%;max-width:none;padding:16px;background-color:var(--c1hslh2c-0);border-radius:3px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;height:100%;min-width:11.2rem;min-height:var(--c1hslh2c-1);height:var(--c1hslh2c-2);cursor:var(--c1hslh2c-3);}
.CardContentContainer_c1od5sky{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;}
.CardImageSection_c10riutw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:9px;}
.ImageContainer_i1mauqcj{display:relative;width:74px;height:74px;}
.Image_iop7k99{height:74px;margin-bottom:8px;width:74px;object-fit:cover;border-radius:3px;}
.Container_c1xyh766{margin-right:12px;}
.PlaceholderIcon_pof0whj{height:74px;margin-bottom:8px;width:74px;object-fit:cover;border-radius:3px;}
.CardContainer_cv8phu5{margin:0;cursor:default;-webkit-break-inside:avoid;break-inside:avoid;visibility:var(--cv8phu5-0);}.CardContainer_cv8phu5 .CardInnerContainer_c1hslh2c{border-top:3px solid var(--cv8phu5-2);}.CardContainer_cv8phu5 .PlaceholderIcon_pof0whj{opacity:var(--cv8phu5-4);}.CardContainer_cv8phu5 .Image_iop7k99{-webkit-filter:var(--cv8phu5-6);filter:var(--cv8phu5-6);opacity:var(--cv8phu5-7);}
.StyledWithInspector_s4jauxg{margin-top:8px;}
.InspectorSocialMediaIcons_i1ur72tf a{color:var(--i1ur72tf-0) !important;}.InspectorSocialMediaIcons_i1ur72tf path{fill:var(--i1ur72tf-1) !important;}
.CardContent_cujazf2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;}
