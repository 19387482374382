.ShareInputs_s1tg5je3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.SharedWithList_sgwer5f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.ShareWithOthersContainer_szs1vts{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;border-top:1px solid #858996;padding-top:1rem;}
.LinkControls_luowev5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ShareLinkContainer_stw9f7b{background-color:#f2f4f5;border-radius:0.375rem;height:3rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0.75rem;-webkit-flex:1;-ms-flex:1;flex:1;font-size:0.875rem;}
.SharedWithItemsContainer_s1ax1aij{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.75rem;padding-top:1.5rem;max-height:15.9375rem;overflow:auto;}
.OwnerShareListItem_o9pvlms button{background-color:transparent !important;}
.GeneratedLink_g1k5idbg{font-size:0.875rem;word-break:break-all;}
.TitleCardContainer_t1o2742c{border:1px solid #ffffff;border-radius:0.75rem;}
.DeleteLinkContainer_d1mec8dy{border:1px solid #f14b4b;padding:0.75rem;border-radius:0.75rem;background-color:#232947;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;-webkit-text-decoration:underline;text-decoration:underline;}
.DeleteLinkExplainer_dc16rsm{margin:0;font-size:0.875rem;color:#b3b5bd;}
.SharePermissionItemSkeletonContainer_sk31mgy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;}
.SharePermissionItemSkeletonContent_s1g1tii4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.SharedWithTitle_scd89cd{margin:0;font-size:0.875rem;}
.LinkButton_ll1rkm4{text-wrap:nowrap;}.LinkButton_ll1rkm4:focus{background-color:none;}.LinkButton_ll1rkm4:hover,.LinkButton_ll1rkm4:focus{background-color:transparent !important;color:#28effa !important;}.LinkButton_ll1rkm4:hover > svg > path,.LinkButton_ll1rkm4:focus > svg > path{stroke:#28effa !important;}.LinkButton_ll1rkm4:disabled{background-color:transparent !important;color:#b3b5bd !important;}.LinkButton_ll1rkm4:disabled > svg > path{stroke:#b3b5bd !important;}
.GeneratedLinkContainer_g1bwmy18{background-color:#355672;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;gap:1rem;border-radius:0.375rem;padding:0.75rem 0 0.75rem 0.75rem;margin-top:1rem;overflow:hidden;}
.ShareConfirmedBanner_s94pnrr{background-color:#61b232;padding:1.375rem 1.75rem 1.75rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0 -2rem -2rem;border-radius:0 0 0.75rem 0.75rem;}
.ShareConfirmedBannerInnerContainer_stme41z{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1.25rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.CheckContainer_c1jdb8qc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#ffffff;border-radius:100px;}
.AddShareEntryControl_a153qcma{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;padding-top:1rem;}
.AddShareEntryError_a1f16mp8{margin-top:1rem;}
.AddShareEntryButton_a6ri0ws{-webkit-align-self:flex-start;-ms-flex-item-align:start;align-self:flex-start;background-color:#28effa;}
.deleteOption_dkqix3t{color:#f14b4b;}.deleteOption_dkqix3t p{color:#f14b4b;}
.generatedLink_gzy4q9m .MuiAvatar-root{background:#232947;border:1px solid #b3b5bd !important;}
.nonGeneratedLink_n1vzd5t6 .MuiAvatar-root{background:transparent;border:1px solid #b3b5bd !important;}.nonGeneratedLink_n1vzd5t6 .MuiAvatar-root > svg{stroke:#b3b5bd;}.nonGeneratedLink_n1vzd5t6 button:disabled{background-color:transparent !important;}.nonGeneratedLink_n1vzd5t6 > div > div:nth-child(2) > div:nth-child(1){color:#b3b5bd;}
.addShareReady_a8hbpw1{outline:2px solid #28effa;}
