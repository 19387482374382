.size0_s1dkt8uk{font-weight:700;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin:0;font-size:4rem;line-height:1.5;-webkit-letter-spacing:-1px;-moz-letter-spacing:-1px;-ms-letter-spacing:-1px;letter-spacing:-1px;}@media screen and (min-width:48rem){.size0_s1dkt8uk{font-size:6rem;line-height:1.16666667;-webkit-letter-spacing:-2px;-moz-letter-spacing:-2px;-ms-letter-spacing:-2px;letter-spacing:-2px;}}
.size1_sstpn3s{font-weight:700;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin:0;font-size:3rem;line-height:1.33333333;-webkit-letter-spacing:-0.9px;-moz-letter-spacing:-0.9px;-ms-letter-spacing:-0.9px;letter-spacing:-0.9px;}@media screen and (min-width:48rem){.size1_sstpn3s{font-size:4rem;line-height:1.5;-webkit-letter-spacing:-1px;-moz-letter-spacing:-1px;-ms-letter-spacing:-1px;letter-spacing:-1px;}}
.size2_sc0ynvq{font-weight:700;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin:0;font-size:2rem;line-height:1.5;-webkit-letter-spacing:-0.69px;-moz-letter-spacing:-0.69px;-ms-letter-spacing:-0.69px;letter-spacing:-0.69px;}@media screen and (min-width:48rem){.size2_sc0ynvq{font-size:3rem;line-height:1.33333333;-webkit-letter-spacing:-0.9px;-moz-letter-spacing:-0.9px;-ms-letter-spacing:-0.9px;letter-spacing:-0.9px;}}
.size3_sro5ryk{font-weight:700;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin:0;font-size:1.5rem;line-height:1.33333333;-webkit-letter-spacing:-0.69px;-moz-letter-spacing:-0.69px;-ms-letter-spacing:-0.69px;letter-spacing:-0.69px;}@media screen and (min-width:48rem){.size3_sro5ryk{font-size:2rem;line-height:1.5;-webkit-letter-spacing:-0.69px;-moz-letter-spacing:-0.69px;-ms-letter-spacing:-0.69px;letter-spacing:-0.69px;}}
.size4_s13t859j{font-weight:700;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin:0;font-size:1.125rem;line-height:1.44444444;-webkit-letter-spacing:-0.48px;-moz-letter-spacing:-0.48px;-ms-letter-spacing:-0.48px;letter-spacing:-0.48px;}@media screen and (min-width:48rem){.size4_s13t859j{font-size:1.5rem;line-height:1.33333333;-webkit-letter-spacing:-0.69px;-moz-letter-spacing:-0.69px;-ms-letter-spacing:-0.69px;letter-spacing:-0.69px;}}
.size5_sfxh22f{font-weight:700;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin:0;font-size:1rem;line-height:1.5;-webkit-letter-spacing:-0.57px;-moz-letter-spacing:-0.57px;-ms-letter-spacing:-0.57px;letter-spacing:-0.57px;}@media screen and (min-width:48rem){.size5_sfxh22f{font-size:1.125rem;line-height:1.44444444;-webkit-letter-spacing:-0.48px;-moz-letter-spacing:-0.48px;-ms-letter-spacing:-0.48px;letter-spacing:-0.48px;}}
.size6_ssluhxf{font-weight:700;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin:0;font-size:0.875rem;line-height:1.44444444;-webkit-letter-spacing:-0.57px;-moz-letter-spacing:-0.57px;-ms-letter-spacing:-0.57px;letter-spacing:-0.57px;}@media screen and (min-width:48rem){.size6_ssluhxf{font-size:1rem;line-height:1.5;-webkit-letter-spacing:-0.57px;-moz-letter-spacing:-0.57px;-ms-letter-spacing:-0.57px;letter-spacing:-0.57px;}}






