$Inter_Regular: "Inter-Regular", sans-serif, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
$Inter_Light: "Inter-Light", sans-serif, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
$Inter_SemiBold: "Inter-SemiBold", sans-serif, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
$InterDisplay: "InterDisplay-SemiBold", sans-serif, -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font_size_document: 16px; //base font size for the HTML document

$font_size_2xs: 0.625rem;
$font_size_xs: 0.75rem;
$font_size_sm: 0.875rem;
$font_size_md: 1rem;
$font_size_lg: 1.125rem;
$font_size_xl: 1.5rem;
$font_size_2xl: 2rem;
$font_size_3xl: 3rem;
$font_size_4xl: 4rem;
$font_size_5xl: 6rem;

$heading_letter_spacing_sm: -0.57px;
$heading_letter_spacing_md: -0.57px;
$heading_letter_spacing_lg: -0.48px;
$heading_letter_spacing_xl: -0.69px;
$heading_letter_spacing_2xl: -0.69px;
$heading_letter_spacing_3xl: -0.9px;
$heading_letter_spacing_4xl: -1px;
$heading_letter_spacing_5xl: -2px;

$line_height__thin: 1.25rem;
$line_height__regular: 1.5rem;

html,
body {
  font-size: $font_size_document;
  font-family: $Inter_Regular !important;
}
