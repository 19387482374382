.ReportScrollContainer_riyyrws{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:stretch;-webkit-justify-content:stretch;-ms-flex-pack:stretch;justify-content:stretch;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media screen{.ReportScrollContainer_riyyrws{margin-top:84px;}}
.ReportOuterContainer_r1b7hfjj{--border-radius:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:start;-webkit-justify-content:flex-start;-ms-flex-pack:start;justify-content:flex-start;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-top:0px;padding-bottom:20px;width:100%;max-width:1080px;background-color:#ffffff;min-height:-webkit-max-content;margin-bottom:35px;border-radius:var(--border-radius);border-top-right-radius:var(--r1b7hfjj-2);border-top-left-radius:var(--r1b7hfjj-2);}.ReportOuterContainer_r1b7hfjj > *{width:100%;}.ReportOuterContainer_r1b7hfjj .report-inner-container{-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;padding:0 25px;}
.ViewerModeHeader_vl6wy8y{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#1fccc0;border-top-left-radius:1.25rem;border-top-right-radius:1.25rem;padding-left:1rem;height:3rem;width:100%;max-width:1080px;}
.ViewerModeHeaderTitle_v1dfchp9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:1.125rem;}
.ViewerModeHeaderLabel_vvkf5jt{padding-left:7px;padding-bottom:1px;}
.PlaceholderSection_p175ti1h{height:300px;padding-left:10px;}
.Loading_lhec1d6 path{fill:var(--lhec1d6-0);}
.InfoContainer_i3jeszc{background-color:#ffffff;color:#474952;max-width:1080px;padding-bottom:20px;border-radius:6px;}
.InnerInfoContainer_iriulp3{padding:25px 5vw;}
.Info_iv8gbim{text-align:center;margin:32px auto 0;max-width:700px;font-size:0.875rem;}
.CloseModalAlertIcon_cv5rx1g{height:21px;width:21px;}.CloseModalAlertIcon_cv5rx1g circle{stroke:transparent;}.CloseModalAlertIcon_cv5rx1g path{fill:#ffffff;}
.CloseModalAlertButton_c1ujfwd7{all:unset;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-transition:background-color 0.3s ease-in-out;transition:background-color 0.3s ease-in-out;}.CloseModalAlertButton_c1ujfwd7:hover,.CloseModalAlertButton_c1ujfwd7:focus{outline:none;background-color:rgba(0,0,0,0.4);}
.Modal_mknttxh{max-width:860px;margin-top:350px;font-size:1rem;}
.ModalBody_m1qtq5jy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:12px;background-color:#355672;font-size:0.875rem;padding:24px;border-radius:12px;}
.ActionButtons_a18l8hov{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;padding:0px;gap:12px;}
.NoTraceRightContent_n1oqtm1d{-webkit-flex:1;-ms-flex:1;flex:1;}
.MainContent_mn0z0ta{margin-bottom:24px;}
.NoReportContext_nu4tpuz{text-align:left;}
.Header_hdqlp8{font-size:1.125rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;line-height:1.5;}
.SubHeader_s15799aa{font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1rem;line-height:1.33333333;margin:16px 0;}
.ExtraGuidanceText_egb4np5{margin:0;margin-top:16px;color:#dfe1e3;}
.PrimaryBannerButton_p9oyu3r{all:unset;border-radius:100px;padding:6px 12px;border:1px solid #ffffff;background-color:#ffffff;color:#232947;}.PrimaryBannerButton_p9oyu3r:focus,.PrimaryBannerButton_p9oyu3r:hover{outline:none;box-shadow:0 0 0 4px rgba(40,239,250,0.33);}.PrimaryBannerButton_p9oyu3r:focus-visible{outline:2px solid blue;}
.SecondaryBannerButton_s1jn5q0w{all:unset;padding:6px 12px;border-radius:100px;border:1px solid #ffffff;}.SecondaryBannerButton_s1jn5q0w:focus,.SecondaryBannerButton_s1jn5q0w:hover{outline:none;box-shadow:0 0 0 4px rgba(40,239,250,0.33);}.SecondaryBannerButton_s1jn5q0w:focus-visible{outline:2px solid blue;}
.modalContent_m1up5koe{border-radius:12px;border:none;}
