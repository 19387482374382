.RelatedOrganisations_r4a4woa{max-height:var(--r4a4woa-0);overflow-y:auto;}
.SectionBanner_sgdqzgt{width:100%;background-color:#f2f4f5;border-radius:2px;padding:8px 16px;}
.SectionBannerInfo_s1en156w{font-size:0.875rem;color:#858996;margin-bottom:0;}
.CustomStickyExpandButton_c1i5bqxu{padding:10px 16px;border-top:2px solid #ffffff;}
.InnerSectionBannerContent_i6554f0{-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.SectionCount_s1uu1k4c{text-align:end;color:#474952;font-size:2rem;margin-left:16px;}
.ConfidenceFilters_cw1j7yd{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:16px;}
.ConfidenceButton_cxe1czy{height:36px;width:318px;width:100%;-webkit-flex:1;-ms-flex:1;flex:1;border:1px solid var(--cxe1czy-0);border-radius:20px;color:var(--cxe1czy-1);font-size:1.125rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:8px 16px;background-color:var(--cxe1czy-3);-webkit-transition:box-shadow 0.2s;transition:box-shadow 0.2s;}.ConfidenceButton_cxe1czy h2{margin:0;}.ConfidenceButton_cxe1czy:hover,.ConfidenceButton_cxe1czy:focus{outline:none;box-shadow:0 0 0 4px var(--cxe1czy-4);}
.Motion_m2xi1db{-webkit-flex:1;-ms-flex:1;flex:1;margin:0 16px;}.Motion_m2xi1db:first-child{margin-left:0;}.Motion_m2xi1db:last-child{margin-right:0;}
.NoResults_n1tzyfbv{height:var(--n1tzyfbv-0);width:100%;border-top:1px solid #ffffff;padding:16px 0 0 16px;color:#858996;font-size:0.875rem;}
.ConfidencebucketCount_c17yb1x{font-size:0.875rem;color:#474952 !important;padding-right:4px;}
.OptionsDropdownMenuItem_o1wxj04g{padding:8px;color:#474952;font-size:0.875rem !important;font-family:Inter-Regular,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:200;-webkit-transition:background-color 0.2s;transition:background-color 0.2s;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}.OptionsDropdownMenuItem_o1wxj04g:hover,.OptionsDropdownMenuItem_o1wxj04g:focus{background-color:var(--o1wxj04g-3);}.OptionsDropdownMenuItem_o1wxj04g:disabled{opacity:0.3;color:#ffffff;}.OptionsDropdownMenuItem_o1wxj04g:focus{outline:none;}.OptionsDropdownMenuItem_o1wxj04g:active{color:#474952;}
.DisabledOptionsDropdownMenuItem_d130r5gq.OptionsDropdownMenuItem_o1wxj04g{opacity:0.3;}
.CountAndMenu_c1ymepxs{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.BuildingsIconContainer_b19so72i{padding-top:10px;}
.WhiteTextCentered_wan49lm{color:#ffffff;text-align:center;}
.OverviewImageContainer_ofh1txw{border-radius:3px;width:74px;height:74px;background-color:var(--ofh1txw-0);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:8px;}
.masonryGrid_munfefg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-left:-8px;width:auto;margin-top:3px;}
.masonryColumn_mt1oib9{background-clip:padding-box;padding-left:10px;}.masonryColumn_mt1oib9 > div{margin-bottom:10px;}.masonryColumn_mt1oib9 > div:last-child{margin-bottom:0px;}
.TooltipContent_t12cfqlf{text-align:left;padding:2px;width:var(--t12cfqlf-0);max-width:275px;max-height:325px;overflow-y:auto;}
