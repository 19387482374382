.SourceItemContainer_s1ca309i{background-color:rgba(0,124,137,0.07);max-height:70px;height:70px;border-radius:4px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-transition:background-color 0.2s;transition:background-color 0.2s;}.SourceItemContainer_s1ca309i:hover,.SourceItemContainer_s1ca309i:focus{background-color:rgba(0,124,137,0.13);cursor:pointer;outline:none;}
.ItemIconContainer_i1jzbbo5{background-color:rgba( var(--i1jzbbo5-0), 0.7 );width:30px;height:var(--i1jzbbo5-1);border-top-left-radius:4px;border-bottom-left-radius:4px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.SourceTitle_slp0mkj{font-size:0.875rem;color:#474952;margin-left:8px;max-width:var(--slp0mkj-2);}
.ItemContents_i105cbal{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;text-align:left;}
.SourceUrl_ssbyin9{font-size:0.875rem;margin-left:8px;max-width:var(--ssbyin9-1);color:#4674ba !important;}
.ItemContentsContainer_i1uscszo{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-right:2px;}
.SourceImage_s1cqjan1{object-fit:cover;height:64px;border-radius:8px;}
.SourceUrlPlaceholder_s1ksdbvc{font-size:0.875rem;color:#858996;margin-left:8px;}
.sourceItemLink_sm2lj2f:not(:last-child){margin-bottom:6px;}
.Quote_qzwing{font-style:italic;display:block;}
