.di-source-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: stretch;
  border-width: 1px;
  border-style: solid;
  border-color: $white;
  // Added for css grid
  width: 507px;
  margin-bottom: 8px;
  color: $grey_mid;
  font-size: $font_size_sm;

  > * {
    padding: 8px;
  }

  &-emphasis {
    border-color: $grey_mid; /* mid grey*/
  }
  &-disabled {
    opacity: 0.4;
  }
  &-inner-panel {
    position: relative;
    overflow: hidden;
    flex: 1; /* stops Safari being nutty it seems https://github.com/philipwalton/flexbugs/issues/197#issuecomment-283211783 */
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }
  &-inner-panel-switcher {
    display: grid;
    position: relative;
    flex: 1 1 0;

    > * {
      grid-area: 1/1;
    }
  }
  &-main-inner-panel-hidden {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
}
.di-inset-text-box {
  border-left: 4px solid $blue_grey;
  padding-left: 0.5rem;
  color: $red;
  font-style: italic;
  margin: 0.2rem;
}
:not(.report-section-content-inner) > .di-source-card {
  padding: 0;
  overflow: visible; /* required so that assessment dropdown is visible */
}
.di-card-main-text {
  flex-grow: 1;
  padding: 0.2rem;
  padding-bottom: 1rem;

  hr {
    margin-top: 0;
  }
}

.media-card {
  background-color: $white;
  width: 480px;
}
