.ListName_l9wv7bd{max-width:360px;color:#474952;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;}
.Location_l1p5dbnj{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.Duration_d5e2shx{color:#858996;padding-bottom:4px;}
.StatusPill_ssbt0pr{background-color:var(--ssbt0pr-0);border-radius:10px;color:#ffffff;padding:0 8px;}
.ConfidenceMenuContainer_cqghaqk{grid-area:cell-confidence;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.Divider_d1rp9bpc{grid-area:divider;border-bottom:1px solid #d2d3d8;margin-left:8px;margin-top:4px;}
.BottomDivider_bko6lx5{grid-area:bottomDivider;margin-left:8px;border-bottom:1px solid #d2d3d8;margin-top:4px;}
.DetailGrid_dr5a4gp{display:grid;grid-template-areas:var(--dr5a4gp-0);grid-template-columns:36% 35% 27% 2%;}.DetailGrid_dr5a4gp > div:not(.ConfidenceMenuContainer_cqghaqk,.Divider_d1rp9bpc,.BottomDivider_bko6lx5){opacity:var(--dr5a4gp-4);-webkit-transition:opacity 1s;transition:opacity 1s;min-width:0;}
.GridCell_gtuiefr{padding:8px;}
.GridCellRowDetails1_g1y3c1e7.GridCell_gtuiefr{grid-area:cell-row-details-1;word-break:break-word;padding-top:0;padding-left:44px;}
.GridCellRowDetails2_g1g280ux.GridCell_gtuiefr{grid-area:cell-row-details-2;word-break:break-word;padding-top:0;}
.GridCellRowDetails3_gx39i37.GridCell_gtuiefr{grid-area:cell-row-details-3;word-break:break-word;padding-top:0;}
.GridCellListName_gn0ltie.GridCell_gtuiefr{grid-area:cell-list-name;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;}.GridCellListName_gn0ltie.GridCell_gtuiefr:hover{cursor:pointer;}
.EntityName_eizdhjm{margin:0;color:#474952;}
.PrimaryEntityInfo_p19sx711{margin:0;color:#858996;}
.GridCellDuration_g1hgj1ib.GridCell_gtuiefr{grid-area:cell-duration;}.GridCellDuration_g1hgj1ib.GridCell_gtuiefr:hover{cursor:pointer;}
.GridCellStatus_g7ku4ni.GridCell_gtuiefr{grid-area:cell-status;}.GridCellStatus_g7ku4ni.GridCell_gtuiefr:hover{cursor:pointer;}
.CollapseButton_c1sxb2ah{outline:none;background:none;border:none;border-radius:20px;width:28px;min-width:28px;height:24px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-transition:background-color 0.2s;transition:background-color 0.2s;}.CollapseButton_c1sxb2ah:focus,.CollapseButton_c1sxb2ah:active{outline:none;background-color:#dfe1e3;}
.CollapseArrow_c1asa31d{height:12px;width:12px;-webkit-transform-origin:center;-ms-transform-origin:center;transform-origin:center;-webkit-transform:var(--c1asa31d-0);-ms-transform:var(--c1asa31d-0);transform:var(--c1asa31d-0);margin-left:var(--c1asa31d-1);}
.SourceUrl_s1v889s3{max-width:300px;}.SourceUrl_s1v889s3 a:hover{color:#8e70b8;}
.FieldBlock_ffj31yi{padding-bottom:12px;}.FieldBlock_ffj31yi:last-child{padding-bottom:0;}
.BlockLabel_buqp39g{color:#858996;text-transform:lowercase;}.BlockLabel_buqp39g:first-letter{text-transform:capitalize;}
.StatusAndTranslationContainer_s1x2nfcc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.TranslationArrowsIcon_t1dj3ojo path{-webkit-transition:fill 0.2s;transition:fill 0.2s;fill:var(--t1dj3ojo-0);}
.TranslationButton_txow3xc{color:var(--txow3xc-0) !important;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:19px;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;min-height:0 !important;-webkit-transition:background 0.2s,border 0.2s,color 0.2s,box-shadow 0.2s;transition:background 0.2s,border 0.2s,color 0.2s,box-shadow 0.2s;box-shadow:none !important;background-color:var(--txow3xc-1) !important;font-size:0.875rem !important;margin:0 6px;border-color:var(--txow3xc-3) !important;}.TranslationButton_txow3xc:hover,.TranslationButton_txow3xc:focus{box-shadow:0 0 0 4px rgba( var(--txow3xc-4), 0.33 ) !important;}.TranslationButton_txow3xc:active{border-width:1px !important;}
.TranslationLanguage_trohgl3{padding-left:6px;}
.dataField_d1skntel{padding-bottom:6px;}.dataField_d1skntel:last-child{padding:0;}.dataField_d1skntel:last-child dd{margin:0;}.dataField_d1skntel dt{margin-bottom:0;text-transform:lowercase;}.dataField_d1skntel dt:first-letter{text-transform:capitalize;}
.RoleDuration_rvpph17{color:#858996;font-size:0.875rem;}
.NoDate_n196vdlw{color:#858996;}
