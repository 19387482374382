.report-section {
  margin-top: 0;
  margin-bottom: 20px;
  position: relative;
}
.section-error-contents {
  padding: 1rem;
}

.report-section-truncator {
  overflow: hidden;
  transition: max-height 0.6s ease-out;
  scroll-behavior: smooth;
}

.expand-section-overlay {
  height: 38px;
  position: absolute;
  bottom: 0px;
  border-top: 8px solid $white;
  padding-top: 8px;
}

.report-section-description {
  color: $grey_hover;
  margin-top: 0;
  margin-bottom: 10px;
  margin-left: 16px;
  font-size: $font_size_sm;
}
