.DropdownHeader_d1rme9dz{height:32px;background-color:var(--d1rme9dz-0);border-top-left-radius:11px;border-top-right-radius:11px;color:#ffffff;padding:0 16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-bottom:none;font-size:1rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.DropdownFooter_d1iatig2{border-top:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:5px;padding:8px 16px 16px 16px;}
.FooterInfoText_fcxf87k{font-size:0.875rem;color:#858996;padding-bottom:2px;}
.DropdownBody_d486rhi{padding:16px;font-size:0.875rem;color:#858996;white-space:normal;}
.ConfirmButton_cscx9xd{color:var(--cscx9xd-0) !important;font-size:0.875rem;min-height:28px !important;border-color:var(--cscx9xd-0) !important;border-width:1px;}.ConfirmButton_cscx9xd:active{border-width:1px !important;}.ConfirmButton_cscx9xd:hover,.ConfirmButton_cscx9xd:focus{box-shadow:0 0 0 4px var(--cscx9xd-3) !important;}
.CancelButton_c1awye85{color:var(--c1awye85-0) !important;min-height:28px !important;font-size:0.875rem;background-color:var(--c1awye85-2) !important;margin-right:8px;}.CancelButton_c1awye85:hover,.CancelButton_c1awye85:focus{box-shadow:0 0 0 4px var(--c1awye85-3) !important;}
.DropdownContainer_d1zo81a{width:426px;}
.DropdownSubtext_d1w1qz7k{background-color:var(--d1w1qz7k-0);padding:12px 14px;color:#858996;font-size:0.875rem;}
.Tooltip_t1lisv5q{display:inline-block;z-index:100;}
.TooltipContent_tzspmt7{font-size:0.875rem;color:#474952;padding:14px;}
