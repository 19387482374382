.Container_c2385vn{border-radius:12px;position:absolute;z-index:99;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0.7);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.Modal_mcldvnc{position:relative;background:#ffffff;border-radius:18px;overflow:hidden;width:526px;padding-bottom:1rem;}
.ModalImage_mt50cev{min-height:280px;}.ModalImage_mt50cev img{max-width:100%;}
.ModalContent_m3do0th{padding:2rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.Heading_h19dyqg4{color:#000000;font-size:1.5rem;}
.Description_d9cdx0i{font-size:0.875rem;color:#6b6b6b;text-align:center;}
.Carousel_c1w62bwo .slide{background:#ffffff;}.Carousel_c1w62bwo .control-dots{position:relative;padding-top:1rem;}.Carousel_c1w62bwo .control-dots .dot{background:#6b6b6b;box-shadow:none;}.Carousel_c1w62bwo .control-dots .dot.selected,.Carousel_c1w62bwo .control-dots .dot:has(~ .selected){opacity:1;background:#6a4394;}
.NextButtonContainer_n1ndnhl2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.NextButton_n1v6x1fw{background:#6a4394;color:#ffffff;padding:1rem 4rem;}.NextButton_n1v6x1fw:active,.NextButton_n1v6x1fw:hover,.NextButton_n1v6x1fw:focus{background:#6a4394;}
.FinishedAnimation_f1tjhx5n{position:absolute;top:0;left:0;width:100%;height:100%;z-index:7;}
