.GoButton_gc68928{min-width:64px !important;opacity:var(--gc68928-0);background-color:var(--gc68928-1) !important;}
.SuggestionItem_s2j98iy{padding:11px 16px 11px 0;margin-bottom:2px;background-color:rgba(255,255,255,0.12);-webkit-transition:0.2s background-color;transition:0.2s background-color;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SuggestionItem_s2j98iy:last-child{margin-bottom:0;}.SuggestionItem_s2j98iy:hover{background-color:rgba(255,255,255,0.2);}.SuggestionItem_s2j98iy:hover .GoButton_gc68928{opacity:1;}
.SuggestionName_s1wtbpq0{font-size:1.125rem;margin-bottom:0;}
.SuggestionUrl_s9jyiuf{font-size:0.875rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;opacity:0.75;}
.SuggestionStraplineAndDescription_ss318t3{font-size:0.875rem;opacity:0.75;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin-bottom:0;word-break:break-word;}
.SuggestionStrapline_s657v0e{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;margin:0;display:inline;}
.SuggestionDescription_s5pkvgi{margin:0;display:inline;}
.LogoContainer_l1ifde4a{width:85px;padding-left:16px;text-align:center;}
.SuggestionBodyContainer_s14d45o0{padding:0 16px;-webkit-flex:1;-ms-flex:1;flex:1;}
.OrgPlaceholderIcon_o1j0wzb8{opacity:0.7;height:40px;width:40px;}.OrgPlaceholderIcon_o1j0wzb8 path{fill:#ffffff;}
.OrgLogo_on2n470{object-fit:contain;}
