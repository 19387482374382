.NewReportButtonContainer_nmbukr{padding-bottom:16px;padding-top:var(--nmbukr-0);}
.OrgLogo_o1ii2wzz{width:74px;height:32px;object-fit:contain;}
.OrgPlaceholderIcon_o1h43lsu{height:32px;width:74px;-webkit-transform:scale(1.25);-ms-transform:scale(1.25);transform:scale(1.25);}.OrgPlaceholderIcon_o1h43lsu path{fill:#d2d3d8;}
.ModalOrgPlaceholderIcon_mw3p66f{height:74px;width:74px;margin-bottom:8px;margin-top:-2px;}.ModalOrgPlaceholderIcon_mw3p66f path{fill:#d2d3d8;}
.PlaceholderContainer_pmlmy2k{margin-bottom:5px;}
.ConfidenceButtonContainer_c11463mp{margin-bottom:5px;}
.StatusCountButton_s1bspvlt{background-color:#eaebec !important;font-size:0.875rem;color:#858996 !important;padding:0 10px !important;min-height:21px !important;border:1px solid transparent !important;min-width:10px !important;}.StatusCountButton_s1bspvlt:hover,.StatusCountButton_s1bspvlt:focus{box-shadow:0 0 0 4px var(--s1bspvlt-3) !important;}
.RiskFlags_r1g4ax4f{display:-webkit-inline-box !important;display:-webkit-inline-flex !important;display:-ms-inline-flexbox !important;display:inline-flex !important;-webkit-flex-direction:row !important;-ms-flex-direction:row !important;flex-direction:row !important;-webkit-box-pack:start !important;-webkit-justify-content:flex-start !important;-ms-flex-pack:start !important;justify-content:flex-start !important;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:4px;-webkit-column-gap:0;column-gap:0;}
.LazyLoadPlaceholder_l7is2ge{height:132px;width:100%;}
.TagColumn_t1y9750n{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding-left:45px;padding-right:0;}
.RoleDuration_r9mril7{display:inline-block;}


.ProfessionalInformationRow_peerr9i .RowSection_rmz0o96,.ProfessionalInformationRow_peerr9i .StatusPills_s1xyvu15,.ProfessionalInformationRow_peerr9i .OrgLogo_o1ii2wzz{opacity:var(--peerr9i-3);-webkit-transition:opacity 1s;transition:opacity 1s;}
.SourceLink_s1mqonrb:hover{color:#ffffff;}
