.RowControlPlaceholder_r7qpszr{width:56px;}
.RowControlButton_ri5gzpq{border:none;background:none;padding:0 16px;}.RowControlButton_ri5gzpq:focus path,.RowControlButton_ri5gzpq:hover:not([disabled]) path{fill:#ffffff;}.RowControlButton_ri5gzpq:focus circle,.RowControlButton_ri5gzpq:hover:not([disabled]) circle{stroke:#ffffff;}.RowControlButton_ri5gzpq svg{height:27px;width:24px;}.RowControlButton_ri5gzpq path{-webkit-transition:fill 0.2s;transition:fill 0.2s;fill:#ffffff;}.RowControlButton_ri5gzpq circle{-webkit-transition:stroke 0.2s;transition:stroke 0.2s;stroke:transparent;}.RowControlButton_ri5gzpq:focus{outline:none;}
.RowContainer_r27kk37{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:12px 0;-webkit-transition:opacity 0.2s;transition:opacity 0.2s;opacity:var(--r27kk37-0) !important;}
.InputElementsContainer_iirbxtc{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;border:1px solid #ffffff;border-radius:18px;-webkit-transition:box-shadow 0.15s ease-in-out;transition:box-shadow 0.15s ease-in-out;width:387px;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;box-shadow:0 0 0 4px var(--iirbxtc-1);}
.TextInput_t1l7gbjz{border:none;height:36px;border-radius:18px;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;box-shadow:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:100%;margin:0;padding:6px 0 6px 13px;font-size:1rem;line-height:1.5;background-color:transparent;background-clip:padding-box;-webkit-transition:border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;transition:border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;}.TextInput_t1l7gbjz::-webkit-input-placeholder{opacity:0.6;}.TextInput_t1l7gbjz::-moz-placeholder{opacity:0.6;}.TextInput_t1l7gbjz:-ms-input-placeholder{opacity:0.6;}.TextInput_t1l7gbjz::placeholder{opacity:0.6;}.TextInput_t1l7gbjz:focus{outline:0;}
.OptionsDropdownToggle_o3kcw20{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:transparent !important;border:none !important;border-radius:18px;padding:6px 8px;padding-right:9px;height:36px;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;-webkit-transition:box-shadow 0.15s ease-in-out;transition:box-shadow 0.15s ease-in-out;color:#ffffff;opacity:var(--o3kcw20-2) !important;}.OptionsDropdownToggle_o3kcw20 path:{fill:#ffffff;}.OptionsDropdownToggle_o3kcw20:focus{box-shadow:0 0 0 4px var(--o3kcw20-4) !important;background-color:transparent;border-color:#ffffff;outline:none;}.OptionsDropdownToggle_o3kcw20:hover,.OptionsDropdownToggle_o3kcw20:active{border-color:#ffffff !important;}
.OptionsDropdownSelectedLabel_o8dudsl{padding-right:10px;}
.OptionsDropdownMenuItem_o1jonlnp{padding:0 8px;color:#ffffff;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;height:28px;}.OptionsDropdownMenuItem_o1jonlnp:hover,.OptionsDropdownMenuItem_o1jonlnp:focus{background-color:rgba(40,239,250,0.2);color:#ffffff;}.OptionsDropdownMenuItem_o1jonlnp:disabled{opacity:0.3;color:#ffffff;}.OptionsDropdownMenuItem_o1jonlnp:focus{outline:none;}.OptionsDropdownMenuItem_o1jonlnp:active{background-color:rgba(40,239,250,0.2);}
.OptionsDropdownMenu_o1a2hhi8{--menu-color:var(--o1a2hhi8-0);min-width:147px;padding:0;-webkit-transform:none !important;-ms-transform:none !important;transform:none !important;top:44px !important;border-color:var(--menu-color);border-radius:6px;max-height:170px;background-color:var(--menu-color);overflow-y:auto;box-shadow:0 0 7px rgb(0 0 0 / 25%);}.OptionsDropdownMenu_o1a2hhi8::-webkit-scrollbar-thumb{background-color:rgba(255,255,255,0.4);}.OptionsDropdownMenu_o1a2hhi8::-webkit-scrollbar-track{background-color:var(--menu-color);display:initial;}
.ArrowDown_a1bkxm8d path{fill:#ffffff;}
.PromptContainer_pdaf3wf{padding:14px;font-size:0.875rem;max-width:168px;text-align:left;}
.InfoIcon_ircz6cq{padding-bottom:4px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin:0;padding-right:10px;margin-left:4px;}.InfoIcon_ircz6cq svg{opacity:0.7;height:18px;width:18px;}.InfoIcon_ircz6cq path{fill:#ffffff;}
