.LineContainer_l1ufqhrr{position:absolute;width:var(--l1ufqhrr-0);background-color:#d2d3d8;height:100%;left:var(--l1ufqhrr-2);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.LineTerminus_l6aj73b{height:1px;width:10px;background-color:#d2d3d8;}
.LineTopCover_l1kumcii{height:13px;width:1px;background-color:#f2f4f5;}
.Line_l1c14xz0{width:1px;height:100%;background-color:#d2d3d8;}
.TimelineContainer_tiqwpcv{position:relative;padding:0 35px;width:var(--tiqwpcv-0);}.TimelineContainer_tiqwpcv ul{padding:0;margin:0;}
.TimelineEvent_t11h4nba{max-width:var(--t11h4nba-0);}
.ListItem_l1tbhopp{list-style:none;position:relative;clear:both;}.ListItem_l1tbhopp .TimelineEvent_t11h4nba{width:50%;margin-top:-75px;}.ListItem_l1tbhopp:first-child .TimelineEvent_t11h4nba{margin-top:0 !important;}.ListItem_l1tbhopp:nth-child(even) .TimelineEvent_t11h4nba{float:left;}.ListItem_l1tbhopp:nth-child(odd) .TimelineEvent_t11h4nba{float:right;}
.ClearingElement_cgjhlg5{clear:both;}
.SectionContent_s12we0y8{background-color:#f2f4f5;width:100%;padding:10px 0;max-height:var(--s12we0y8-1);overflow-y:auto;}
.SectionBanner_s1kmx0s7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;height:54px;width:100%;background-color:#f2f4f5;margin-bottom:2px;padding:0 16px;}
.SectionBannerInfo_s13od21s{font-size:0.875rem;color:#858996;margin-bottom:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;width:100%;}
.SectionCount_s1flk854{font-size:2rem;color:#474952;}
.CustomStickyExpandButton_c1yejrg4{padding:10px 16px;border-top:2px solid #ffffff;}
.Fluenci_f1gs4o4y{color:#474952;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
