.CardContainer_cflm3ue{background:#ffffff;border-radius:4px;padding:16px;}.CardContainer_cflm3ue:not(:last-child){margin-bottom:12px;}
.CardInnerContainer_cpkwemg{text-align:left;}
.CardHeader_c1my6xoq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.SourceDescription_s1tpb4ws{display:-webkit-box;-webkit-line-clamp:6;-webkit-box-orient:vertical;overflow:hidden;border-top:1px solid rgba(0,124,137,0.30);padding-top:14px;margin-top:8px;font-size:0.875rem;cursor:initial;}
.RiskCategories_r3dmi3p{margin-top:10px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:6px;-webkit-flex-flow:wrap;-ms-flex-flow:wrap;flex-flow:wrap;}
.CardFooter_cynu264{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-top:1px solid rgba(0,124,137,0.30);padding-top:10px;margin-top:8px;}
.SourceTitle_s1jt9e2t{font-size:1rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;line-height:1.38;margin-right:4px;}
.SourceImage_sqwz4ub{object-fit:cover;height:60px;width:60px;border-radius:8px;}
.SourceLink_sccjk12{font-size:0.875rem;color:#007c89 !important;cursor:pointer;}.SourceLink_sccjk12:hover{color:#00a8e5 !important;}
.RiskCountButton_r1qxec09{background-color:#eaebec !important;font-size:0.875rem;color:#858996 !important;padding:0 10px !important;min-height:21px !important;border:1px solid transparent !important;min-width:10px !important;}.RiskCountButton_r1qxec09:hover,.RiskCountButton_r1qxec09:focus{box-shadow:0 0 0 4px var(--r1qxec09-3) !important;}
.RiskCategoryPill_r3o6cfv{padding:0px 8px;border:1px solid #ef7273 !important;border-radius:16px;margin-right:6px;font-size:0.875rem;margin-bottom:6px;text-transform:capitalize;background-color:var(--r3o6cfv-2) !important;color:var(--r3o6cfv-3) !important;min-height:22px !important;box-shadow:none !important;min-width:0 !important;}.RiskCategoryPill_r3o6cfv:hover,.RiskCategoryPill_r3o6cfv:focus{box-shadow:0 0 0 4px rgba(239,114,115,0.25) !important;cursor:pointer;}
.CardTextContainer_ch1vkj1{padding-top:14px;margin-top:8px;max-height:142px;border-top:1px solid rgba(0,124,137,0.30);overflow:auto;}
.CardSourceText_c8gu3jp{font-size:0.875rem;}
.NoTitleHeader_n19tlzs{font-style:italic;padding-right:1px;}
.Snippet_sjnl9j4{font-size:0.875rem;margin-bottom:8px;cursor:initial;}.Snippet_sjnl9j4:last-child{margin-bottom:0;}
.SourceDescriptionContainer_sowfml4{cursor:pointer;}
.NoSnippet_n1epx81y{font-size:0.875rem;color:#858996;font-style:italic;}
.SourceTitleLink_s1ikrdf6{white-space:pre-wrap;}
.TruncatedSourceTitleLink_tijs1a1{white-space:pre-wrap;overflow:hidden;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;}
.SourceUrlPlaceholder_s1li7fk5{font-size:0.875rem;color:#858996;}
.boldenRisk_b13syyqy{font-size:0.875rem;color:#ef7273;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.sourceLink_sml086r{width:94%;}
.sourceDate_s1v2clgb{margin-left:0;}
