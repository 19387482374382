.AddUserControlContainer_atcse3o{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;padding:1rem 0;}
.Explainer_e1lcs3zy{margin:0;color:#b3b5bd;font-size:0.875rem;}
.DoneButton_d1gfgs59{-webkit-flex:1;-ms-flex:1;flex:1;}
.MembersContainer_m1jkqki7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;border-top:1px solid #d2d3d8;padding-top:1.5rem;}
.MembersHeader_m5ofz8f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.MembersList_mbh7pxm{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.75rem;max-height:12.5rem;overflow:auto;}
.MembersError_m4sky89{margin:0;color:#b3b5bd;}
.GroupDetailsContainer_gboow7s{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding:0.75rem;background-color:#232947;border-radius:0.75rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.EditIcon_eadumsd{width:1.5rem;height:1.5rem;margin-right:0.75rem;cursor:pointer;}
.GroupDetailsContentContainer_g12crfov{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.75rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex:1;-ms-flex:1;flex:1;}
.Details_d15j1s7q{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.25rem;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex:1;-ms-flex:1;flex:1;}
.EditDetailsTooltipContent_ec3e21i{padding:0.875rem;font-size:0.875rem;white-space:nowrap;}
.Input_i1nrzbjp{all:unset;background-color:transparent;width:100%;}.Input_i1nrzbjp::-webkit-input-placeholder{opacity:0.5;}.Input_i1nrzbjp::-moz-placeholder{opacity:0.5;}.Input_i1nrzbjp:-ms-input-placeholder{opacity:0.5;}.Input_i1nrzbjp::placeholder{opacity:0.5;}
.GroupNameInput_g1s936ri.Input_i1nrzbjp{font-size:1.125rem;line-height:1.625rem;}
.GroupDescriptionInput_g1xx3d22.Input_i1nrzbjp{line-height:1.5rem;color:#d2d3d8;}
.optionButton_own42zt button:disabled{background-color:transparent !important;}
.remove_ryy5u2r > button{color:#f14b4b;}.remove_ryy5u2r > button p{color:#f14b4b;}
