.TooltipContent_t19zt6dz{text-align:left;overflow-y:auto;font-size:0.875rem;}
.TooltipHeader_tcdzdai{border-bottom:1px solid #d2d3d8;padding-bottom:6px;}
.TooltipBody_t1xay4q6{padding:8px 0;}
.SearchDetails_s117ulce{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.InfoIcon_i1uofbrw{margin-left:2px;display:inline;}.InfoIcon_i1uofbrw > div{display:inline;}
.OldReportPrompt_o97h6r9{background-color:var(--o97h6r9-0);width:100%;min-height:92px;border-radius:12px;padding:16px;margin:10px 0;font-size:0.875rem;color:#474952;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:20px;}
.PromptText_pwicffn{max-width:790px;}
.RegenIcon_r1oijvha{height:48px;min-width:42px;margin-left:4px;}.RegenIcon_r1oijvha path{fill:var(--r1oijvha-0);}
.Link_l3wpfw1{color:var(--l3wpfw1-0) !important;-webkit-text-decoration:underline !important;text-decoration:underline !important;}
.ReportDetailsContainer_r4d844n{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ReportDetails_rqywf1p{color:#858996;font-size:0.875rem;padding-left:13px;}.ReportDetails_rqywf1p span + span{border-left:1px solid #d2d3d8;padding-left:0.625rem;margin-left:0.625rem;}
.AccessButton_a1lroewv{background-color:#1fccc0;color:#ffffff;}
.dataField_dq1g9y3 dt{margin:0;}.dataField_dq1g9y3 dd{margin-bottom:8px;}
.infoIconTooltipContent_ig5jb32{width:280px !important;}
