.DefineStageContainer_d1gixr1x{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;max-width:560px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-transition:box-shadow 0.2s;transition:box-shadow 0.2s;padding:40px 0;border-radius:12px;}
.VerificationElementsContainer_vudn1g9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;position:relative;width:100%;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-top:32px;}
.VerifyingFooterElements_v4y4j3f{-webkit-transition:opacity 0.2s;transition:opacity 0.2s;opacity:var(--v4y4j3f-0);visibility:var(--v4y4j3f-1);top:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.VerifyingSpinner_vu5a38r{color:#28effa;height:36px;width:36px;}
.CheckingEnquiryText_c1q7xwh8{font-size:1rem;color:#28effa;background:none;border:none;padding:0;border-bottom:1px solid transparent;-webkit-transition:border-color 0.2s;transition:border-color 0.2s;}
.SearchAssistNotification_s1ph2jkw{width:500px;position:absolute;left:0;right:0;margin:0 auto;background-color:#2d3c5a;z-index:100;bottom:4.6875rem;}
.ErrorNotificationHeadingLine_e6vxvgk{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.ErrorNotificationDetails_e1jzdab8{padding:10px;}
.ValidationButton_vvumkk1{margin-left:10px;color:#ffffff !important;padding:0 20px !important;border-color:var(--vvumkk1-1) !important;}.ValidationButton_vvumkk1:hover,.ValidationButton_vvumkk1:focus{box-shadow:0 0 0 4px var(--vvumkk1-2) !important;}
.EmboldenedStatement_ezh4ii{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;padding-bottom:5px;padding-top:10px;}
.NotificationGuidance_n1uhh9n0{padding:10px 0;}
.GoButton_gbyi73p{background-color:var(--gbyi73p-0) !important;}.GoButton_gbyi73p:hover,.GoButton_gbyi73p:focus{box-shadow:0 0 0 4px var(--gbyi73p-1) !important;}
