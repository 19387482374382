.SanctionsIcon_sui1imf{width:64px;height:64px;padding-bottom:7px;}.SanctionsIcon_sui1imf path{fill:var(--sui1imf-1);}
.WatchlistsIcon_wil3ars{width:64px;height:64px;padding-bottom:7px;}.WatchlistsIcon_wil3ars path{fill:var(--wil3ars-1);}
.PepsIcon_p28i77e{width:64px;height:64px;padding-bottom:7px;}.PepsIcon_p28i77e path{fill:var(--p28i77e-1);}
.ScreeningSummaryInfo_s19fv4me{height:100%;overflow:auto;padding-top:6px;}
.ListDetail_lf2odvi{font-size:0.875rem;background-color:#f2f4f5;border-radius:6px;padding:16px;padding-top:12px;}.ListDetail_lf2odvi:not(:last-of-type){margin-bottom:8px;}
.EntityName_exlgan8{color:#474952;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.Lists_l8023tw > div:not(:last-of-type){border-bottom:1px solid #d2d3d8;padding-bottom:8px;}
.ListItem_lmc1950{margin-top:8px;}.ListItem_lmc1950:first-of-type{margin-top:0;}
.ListName_l1apsgss{display:inline-block;}
.ListDate_l1smi7wr{color:#858996;}
.AdditionalLabel_aj43y2v{font-size:0.625rem;color:var(--aj43y2v-1);opacity:var(--aj43y2v-2);-webkit-transition:color 0.2s,opacity 0.1s;transition:color 0.2s,opacity 0.1s;}
.GraphicContainer_g1chaidx{text-align:center;padding-top:4px;width:108px;color:var(--g1chaidx-0);}.GraphicContainer_g1chaidx:hover .AdditionalLabel_aj43y2v{color:var(--g1chaidx-2);}
.GraphicHeader_gauivoa{margin:0;font-size:1.5rem;}
.GraphicSubtext_g4h7k6g{font-size:0.875rem;margin:0;margin-top:2px;}
.ScreeningGraphicSubtext_s1fde3e8{text-transform:capitalize;}
.ListStatus_l1k1d15o{color:#858996;}
.ListStatusLabel_lyp1r8q{color:#ef7273;}
.BulletPoint_b5g2e38{height:4px;width:4px;display:inline-block;border-radius:50%;margin-right:8px;background-color:#474952;margin-top:3px;margin-bottom:3px;}
.PersonDetails_p13g2u1h{margin-bottom:6px;}
.InfoSummaryRow_ifkqfrw:not(:last-of-type){margin-bottom:8px;}
.SectionLinkButton_s1fuyx3r{border-bottom:1px solid #858996 !important;color:#858996 !important;font-size:0.875rem !important;line-height:13px;}
.SectionLinkText_s18ogjml{white-space:nowrap;}
.AdditionalLabelExplainer_a1obhfd9{padding-top:8px;}
.TooltipExplainerSection_t18wue1z{margin-top:16px;}
.RelationLabel_rc27zd9{color:#474952;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.SourceItemsContainer_s48y784{height:100%;overflow:auto;}
.riskPill_r811eqe{text-transform:capitalize;}
