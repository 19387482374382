.EventContainer_e1v5zy4w{position:relative;text-align:var(--e1v5zy4w-0);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:var(--e1v5zy4w-1);-webkit-box-align:var(--e1v5zy4w-1);-ms-flex-align:var(--e1v5zy4w-1);align-items:var(--e1v5zy4w-1);margin-right:var(--e1v5zy4w-2);margin-left:var(--e1v5zy4w-3);text-align:var(--e1v5zy4w-4);}
.DateRange_duj5d2j{font-size:0.875rem;color:#474952;padding-bottom:6px;}
.EventTitle_e1omzdny{font-size:1.125rem;margin-bottom:6px;overflow:hidden;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;}
.EventSubtitle_eui4r5j{margin:0;font-size:0.875rem;color:#858996;padding-bottom:6px;}
.EventContent_eof4pbi{font-size:0.875rem;color:#474952;margin-bottom:6px;padding-right:var(--eof4pbi-2);margin-right:var(--eof4pbi-3);}
.DateTag_d1dndw61{margin:0;padding:3px 0;color:#fff;font-size:0.875rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:var(--d1dndw61-1);-webkit-justify-content:var(--d1dndw61-1);-ms-flex-pack:var(--d1dndw61-1);justify-content:var(--d1dndw61-1);}
.DateTagInnerContainer_d9886db{position:relative;}
.DateString_d1kbf7n7{position:absolute;top:2px;right:var(--d1kbf7n7-0);}
.DateTagIcon_daxxjpd{height:24px;width:83px;-webkit-transform:var(--daxxjpd-0);-ms-transform:var(--daxxjpd-0);transform:var(--daxxjpd-0);}.DateTagIcon_daxxjpd path{fill:var(--daxxjpd-1);}
.EventNodeContainer_edo75zr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:var(--edo75zr-0);-ms-flex-direction:var(--edo75zr-0);flex-direction:var(--edo75zr-0);}
.EventNode_e1ma0f79{width:var(--e1ma0f79-0);height:var(--e1ma0f79-0);background-color:var(--e1ma0f79-2) !important;border-radius:50%;border:1px solid var(--e1ma0f79-3);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;position:absolute;}
.InnerEventNodeCircle_i1wnmmuw{height:var(--i1wnmmuw-0);width:var(--i1wnmmuw-0);background-color:var(--i1wnmmuw-2);border-radius:50%;}
.EventLine_e1xd60ph{width:30px;border-top:2px dotted var(--e1xd60ph-0);}
.EventContentContainer_e9jz3kv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:var(--e9jz3kv-0);-ms-flex-direction:var(--e9jz3kv-0);flex-direction:var(--e9jz3kv-0);}
.PaddingDiv_p1puxa7v{padding-left:var(--p1puxa7v-0);}
.ContentDiv_cnglwwn{padding:5px 0;}
.NoTitleHeader_no5t9zx{font-style:italic;padding-right:1px;}
.TimelineFragment_t1co7oba{position:absolute;left:calc(50% - 5px);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.Terminus_tl9ikns{height:1px;width:10px;background-color:#d2d3d8;}
.Line_lbtvho6{width:1px;height:150px;background-color:#d2d3d8;}
.EventNodeOuterContainer_e5kegco{position:relative;height:var(--e5kegco-0);width:var(--e5kegco-0);}
.RiskCategoryPill_r12k2whx{padding:0px 8px;border:1px solid #ef7273 !important;border-radius:16px;margin-right:6px;font-size:0.875rem;margin-bottom:6px;text-transform:capitalize;background-color:var(--r12k2whx-2) !important;color:var(--r12k2whx-3) !important;min-height:21px !important;box-shadow:none !important;}.RiskCategoryPill_r12k2whx:hover{box-shadow:0 0 0 4px rgba(239,114,115,0.25) !important;cursor:pointer;}
.RiskCountButton_r1avwpt1{background-color:#eaebec !important;font-size:0.875rem;color:#858996 !important;padding:0 10px !important;min-height:21px !important;border:1px solid transparent !important;min-width:10px !important;}.RiskCountButton_r1avwpt1:hover,.RiskCountButton_r1avwpt1:focus{box-shadow:0 0 0 4px var(--r1avwpt1-3) !important;}
.SourceItemsContainer_sa2ue6z{height:100%;overflow:auto;}
.SourcesSubtitle_s1i1mw1j{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.riskPill_r2s93jy{text-transform:capitalize;}
