.OrganisationOverviewSection_o1u476py{color:#474952;margin-bottom:20px;}
.OverviewContainer_ob5yfjf{display:grid;grid-template-areas:var(--ob5yfjf-0);grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;}
.OverviewCell_o16yqfvi{background-color:#f2f4f5;border:1px solid white;padding:16px;}
.OrgLogo_o1bt5zyy.OverviewCell_o16yqfvi{grid-area:org-logo;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;border-top:none;padding-top:10px;}
.OrgDetails1Cell_otqv5ru.OverviewCell_o16yqfvi{grid-area:org-details-1;}
.OrgDetails2Cell_o1ufy40j.OverviewCell_o16yqfvi{grid-area:org-details-2;overflow-y:auto;}
.OrgPresence_o19m2a1r.OverviewCell_o16yqfvi{grid-area:org-scale;position:relative;}
.OrgLogoContainer_o4maoew{object-fit:contain;height:88px;}
.OrgInfoGraphic_o15pqzyi.OverviewCell_o16yqfvi{grid-area:org-info-graphic;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;padding-top:20px;border-top:none;}
.RiskIcons_r1y8zdo4.OverviewCell_o16yqfvi{grid-area:risk-icons;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;border-top:none;height:155px;padding-bottom:0;padding-top:4px;}
.ScreeningRiskIcons_s1tu2m0h.OverviewCell_o16yqfvi{grid-area:screening-risk-icons;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;border-top:none;height:155px;padding-bottom:0;padding-top:4px;}
.OrgScaleItem_oh6jqz2{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:9px;}
.ScreeningRiskHeader_s1uo3y06.OverviewCell_o16yqfvi{grid-area:screening-risk-icons-title;padding:0;padding-top:12px;padding-left:16px;border:1px solid #ffffff;border-top:none;border-bottom:none;}
.RiskHeader_r1eig7j5.OverviewCell_o16yqfvi{grid-area:risk-icons-title;padding:0;padding-top:12px;padding-left:16px;border:1px solid #ffffff;border-top:none;border-bottom:none;}
.OrgLogoHeader_orvhgol.OverviewCell_o16yqfvi{grid-area:org-logo-title;padding:0;padding-top:12px;padding-left:16px;border:1px solid #ffffff;border-top:none;border-bottom:none;}
.EmployeeCardIcon_e1tge0cw{width:30px;height:30px;}.EmployeeCardIcon_e1tge0cw path{fill:var(--e1tge0cw-1);}
.RelatedOrgsIcon_r4c8fzm{width:30px;height:30px;}.RelatedOrgsIcon_r4c8fzm path{fill:var(--r4c8fzm-1);}
.GlobeIcon_g1jxviqy{width:30px;height:30px;}.GlobeIcon_g1jxviqy path{fill:var(--g1jxviqy-1);}
.SocialReachIcon_s13mn5zp{width:30px;height:30px;}.SocialReachIcon_s13mn5zp path{fill:var(--s13mn5zp-1);}
.StateOwnedFlagIcon_se8s5p6{width:64px;height:64px;padding-bottom:7px;}.StateOwnedFlagIcon_se8s5p6 path{fill:var(--se8s5p6-1);}
.MajorCrimesFlagIcon_mmi0p4e{width:64px;height:64px;padding-bottom:7px;}.MajorCrimesFlagIcon_mmi0p4e path{fill:var(--mmi0p4e-1);}
.FinCrimeRiskFlagIcon_f5jei7b{width:64px;height:64px;padding-bottom:7px;}.FinCrimeRiskFlagIcon_f5jei7b path{fill:var(--f5jei7b-1);}
.ESGIcon_e1wzdaoi{width:64px;height:64px;padding-bottom:7px;}.ESGIcon_e1wzdaoi path{fill:var(--e1wzdaoi-1);}
.RiskFlagIcon_r6juu74{width:64px;height:64px;padding-bottom:7px;}.RiskFlagIcon_r6juu74 path{fill:var(--r6juu74-1);}
.GraphicSubtext_g1ns6k2s{font-size:0.875rem;color:#858996;margin:0;margin-top:2px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.OrgScaleHeader_o1tfjufp{font-size:0.875rem;padding:0 4px;color:var(--o1tfjufp-1);font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.OrgScaleSubtext_o49cvzk{font-size:0.875rem;color:var(--o49cvzk-1);}
.SocialReachGraphicSubtext_sr31iis.OrgScaleSubtext_o49cvzk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.OrgProfiles_owf7e2s.OverviewCell_o16yqfvi{grid-area:org-profiles;overflow-y:auto;padding-right:11px;}
.OrgSummary_o7ksix2.OverviewCell_o16yqfvi{grid-area:org-summary;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;position:relative;}.OrgSummary_o7ksix2.OverviewCell_o16yqfvi p{font-size:0.875rem;margin:0;}
.OrgSummaryContainer_o19u8ej{max-height:170px;overflow-y:auto;}
.LinkToSummary_lp3cn5w{color:#858996 !important;font-size:0.875rem !important;min-width:0;border-bottom:1px solid transparent;}.LinkToSummary_lp3cn5w:hover,.LinkToSummary_lp3cn5w:focus{border-color:#858996;}
.OrgDescription_o1rb59q7.OverviewCell_o16yqfvi{grid-area:org-description;}.OrgDescription_o1rb59q7.OverviewCell_o16yqfvi p{font-size:0.875rem;margin-bottom:0;}
.OrgDescriptionContainer_ox3vsdl{font-size:0.875rem;margin-bottom:0;height:170px;overflow-y:auto;}
.QuotesIcon_qxbmxek{float:left;margin:5px 10px 0 0;}.QuotesIcon_qxbmxek path{fill:var(--qxbmxek-0);}
.OrgDescriptionText_ohr6paz:hover{cursor:var(--ohr6paz-0);}
.SectionTitle_s7qesf0{color:#858996;font-size:0.875rem;margin:0;}
.OrgScaleTitle_o1gipmlk.SectionTitle_s7qesf0{margin-bottom:4px;}
.OnlineProfilesTitle_o12y0ft.SectionTitle_s7qesf0{margin-bottom:4px;}
.OrgDescriptionTitleContainer_oyh0bwj{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.OrgDescriptionTitle_otmz6gq{color:#858996;font-size:0.875rem;margin:0;max-width:220px;display:inline;}
.ShowAllButton_s1wtnq58{color:#858996 !important;font-size:0.75rem !important;min-width:0;padding-top:8px !important;}.ShowAllButton_s1wtnq58:hover,.ShowAllButton_s1wtnq58:focus{border-color:#858996 !important;}
.OrgDuration_opwthsx{color:#858996;}
.HQContainer_hutjv99{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.JurisdictionContainer_jbi38yi{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.CustomFlag_c147mjz{width:31px;}.CustomFlag_c147mjz img{width:24px !important;height:16px !important;margin:0;margin-top:1px !important;margin-right:5px !important;border-radius:2px !important;}
.InformationIcon_iibqhg9{height:20px;width:20px;padding-left:2px;}
.PopoverHomepageImage_p2db7ww{padding-right:6px;}
.ImageLoadingSpinner_i1af320o{color:#1fccc0;position:absolute;}
.HomepageLink_hegvk63{color:#858996 !important;font-size:0.875rem;}.HomepageLink_hegvk63:hover{color:#00a8e5 !important;}
.OnlineProfilesContainer_o10x1wcb{max-height:140px;overflow-y:auto;padding-right:5px;}
.TooltipContent_t1w6hptm{text-align:left;padding:2px;max-height:325px;overflow-y:auto;width:240px;}
.DescriptionSentenceSeparator_dpdghqk{white-space:pre;margin:0 2px;}
.DescriptorSentenceContainer_d14niecj{display:inline;}.DescriptorSentenceContainer_d14niecj:last-child .DescriptionSentenceSeparator_dpdghqk{margin:0;}
.DescriptorSources_d1y5x7bx{font-size:0.875rem;color:#858996;}.DescriptorSources_d1y5x7bx a{color:#858996;}.DescriptorSources_d1y5x7bx a:hover{color:#474952;}
.DescriptionSourceContainer_dj5bu7j{max-width:400px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
.NoDate_n3vt36f{color:#858996;}
.RoleDuration_r16u9r41{color:#858996;}
.Info_i10z10bz{font-size:0.875rem;color:#858996;white-space:nowrap;}
.Founders_fqqb5w1:hover{cursor:pointer;}
.WebsitePlaceholder_wpxf6a1{max-width:224px;max-height:120px;border-radius:3px;}
.SocialReachTooltipList_styktew{padding-top:8px;padding-left:25px;margin:0;}
.NoSocialPresenceLabel_npklk18{margin:0;padding-bottom:8px;}
.SummaryText_s8fosbg{word-break:break-word;}.SummaryText_s8fosbg:hover{cursor:var(--s8fosbg-0);}
.PlaceholderText_p5z62rt{font-style:italic;color:#b3b5bd;}
.SummaryTooltipContent_srrd6jb.TooltipContent_t1w6hptm{width:400px;}
.InfographicContainer_i1ho4ayu{position:relative;margin:auto;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}
.OrgScaleIconContainer_op8td4e{position:relative;}
.RiskCountIcon_rdezcem{position:absolute;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;aspect-ratio:1;min-width:21px;bottom:-6px;right:-3px;border-radius:50%;font-size:0.625rem;background-color:#ef7273;line-height:17px;color:#ffffff;border:2px solid #f2f4f5;padding:var(--rdezcem-4);}.RiskCountIcon_rdezcem:hover{cursor:pointer;}
.RiskTooltipBody_r10a72es{max-height:200px;overflow-y:auto;padding-top:5px;}
.RiskList_r9nu1xg{margin-bottom:8px;line-height:1.3;font-size:0.875rem;}
.Risks_r15rvx36{color:#858996;}
.RiskAssignerText_r79su38{color:#858996;}
.StreetviewImage_sm3egcz{border-radius:3px;}
.Tooltip_t12a0xp6 + div{display:inline;}
.FounderPill_f1xnq9uk{text-align:left;}
.Duration_dgm7w6{color:var(--dgm7w6-0) !important;font-size:0.875rem;}.Duration_dgm7w6 .NoDate_n3vt36f{color:var(--dgm7w6-3);}
.FounderContainer_fnty560{padding-bottom:6px;}
.SmallEmployeeIconInfographic_s69ip5w{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SmallEmployeeIconInfographic_s69ip5w .EmployeeCardIcon_e1tge0cw{height:35px;width:35px;padding-bottom:0;}
.SmallSocialReachIconInfographic_s1pqg9m1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SmallSocialReachIconInfographic_s1pqg9m1 .SocialReachIcon_s13mn5zp{height:35px;width:35px;padding-bottom:0;}
.HomepageProfile_h1dke4o7{padding:2px 4px;}
.BuildingIconContainer_biiawbw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#dfe1e3;height:64px;width:64px;border-radius:3px;}
.BuildingIcon_b1goveut path{fill:#ffffff;}
.MediaIconsContainer_mr0oup2{padding:var(--mr0oup2-0);}
.SocialReachInfoIcon_shazzw9{margin-bottom:3px;}
.BulletPoint_b5c6oaw{height:4px;width:4px;display:inline-block;border-radius:50%;margin-right:8px;background-color:#474952;margin-top:3px;margin-bottom:3px;}
.SourceContainer_slyqa4j{max-height:275px;overflow:auto;}
.CountryFlag_c124idud{width:30px;}.CountryFlag_c124idud img{width:24px !important;height:18px !important;margin:0;margin-right:8px !important;border-radius:2px !important;}
.Country_cojaaro{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.InspectorContent_ii9j864{max-height:175px;overflow:auto;margin-right:-5px;padding-right:5px;}
.StateOwnedCompany_s1pa84as:not(:first-of-type){border-top:1px solid #d2d3d8;margin-top:11px;padding-top:2px;}
.RiskCountry_rhp1lhc:not(:first-of-type){border-top:1px solid #d2d3d8;margin-top:8px;padding-top:8px;}
.CountryName_c1gqr4p0{font-size:0.875rem;padding-bottom:4px;}
.CompaniesList_c2dxos4{padding-left:var(--c2dxos4-0);}
.CompanyField_c11zzkvo{padding-left:11px;text-indent:-12px;}
.RelatedCountryHeader_r4shtce{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.RiskAssigners_rnmgdss{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-top:8px;gap:8px;}
.CustomSectionFooter_ct17ngc{border:1px solid #ffffff !important;}
.ExplainerText_e1v4l4uv{font-size:0.875rem;}
.OrgIconContainer_og9o1m0{width:36px;height:36px;border-radius:3px;background-color:#1fccc0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:8px;}
.OrgIcon_o1fzqe8n{-webkit-transform:scale(0.65);-ms-transform:scale(0.65);transform:scale(0.65);}
.InfoSummaryRow_i150famd:not(:last-of-type){margin-bottom:8px;}.InfoSummaryRow_i150famd ul{padding:0 14px;margin:0;}
.SectionLinkButton_sigod05{border-bottom:1px solid #858996 !important;color:#858996 !important;font-size:0.875rem !important;line-height:13px;}
.List_lkah6tm{margin-top:11px !important;padding-top:14px !important;padding-left:0 !important;border-top:1px solid #d2d3d8;list-style:none;}
.ListItem_lt6e5uo{font-size:0.875rem;}.ListItem_lt6e5uo + .ListItem_lt6e5uo{margin-top:12px;}
.CompanyLocation_c9qjy15{color:#858996;}
.RiskIconsSectionTitle_r1y5th09.SectionTitle_s7qesf0{margin-bottom:4px;}
.OrgLogoSectionTitle_o5lzj6g.SectionTitle_s7qesf0{margin-bottom:4px;}
.NoneIdentified_nly0jwe{color:#b3b5bd;font-size:0.875rem;font-style:italic;}
.StateOwnedCompanies_s18th7bo{overflow:auto;height:100%;}
.CustomSourceList_c1hy9g7h{overflow:inherit;}
.IndirectExplainer_iddpvk1{padding-top:8px;}
.dataField_du4rhgp dt{color:#474952;margin:0;}.dataField_du4rhgp dd{margin-bottom:8px;}
.orgLogoImage_oatv2el{border-radius:3px;height:88px;width:94px;object-fit:contain;}
.locationRiskTag_l7t3xqb{border-radius:11px;height:23px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-left:8px;opacity:1 !important;background-color:#ef7273 !important;}.locationRiskTag_l7t3xqb > svg{margin-top:0px;margin-right:-3px;}
