.Container_c1k1y9o1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.Description_d1lw0log{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;}
.Inputs_iiqz4qq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;width:100%;margin-bottom:1rem;}.Inputs_iiqz4qq input{width:100%;}
.Title_tg7ry3j{color:#ffffff;}
.Subtitle_s1ur4l1o{color:#b3b5bd;font-size:0.875rem;margin:0;}
.Divider_djh32bj{border-top:1px solid #ffffff;marign:0;}
.ButtonsContainer_b1si682e{width:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;}.ButtonsContainer_b1si682e > *{-webkit-flex:1;-ms-flex:1;flex:1;}
.CheckboxLabel_c1p8lga0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.5rem;}
.Link_l1ieynf0{color:#28e4fa;}
.PasswordInvalid_p12d94wo{color:#ff9d00;}
.PasswordValid_pbvj5mo{color:#28e4fa;}
.ContactSupportLink_c2cj1o9{color:#00a8e5 !important;cursor:pointer;-webkit-text-decoration:none !important;text-decoration:none !important;}.ContactSupportLink_c2cj1o9:hover{-webkit-text-decoration:underline !important;text-decoration:underline !important;}
