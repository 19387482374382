.Heading1_h1l8c0d1{display:inline-block;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1.5rem;-webkit-letter-spacing:-0.69px;-moz-letter-spacing:-0.69px;-ms-letter-spacing:-0.69px;letter-spacing:-0.69px;line-height:1.33333333;margin:0;margin-bottom:12px;}
.Heading2_h1ovlyl9{display:inline-block;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1.125rem;-webkit-letter-spacing:-0.48px;-moz-letter-spacing:-0.48px;-ms-letter-spacing:-0.48px;letter-spacing:-0.48px;line-height:1.44444444;margin-bottom:8px;}
.Heading3_h1vdygr7{font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1rem;-webkit-letter-spacing:-0.57px;-moz-letter-spacing:-0.57px;-ms-letter-spacing:-0.57px;letter-spacing:-0.57px;line-height:1.5;margin-bottom:4px;color:#1F7DD9;}
.Heading4_h1c3zv7e{font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;-webkit-letter-spacing:-0.57px;-moz-letter-spacing:-0.57px;-ms-letter-spacing:-0.57px;letter-spacing:-0.57px;margin-bottom:0.5rem;}
.Paragraph_p1h47fyc{font-size:0.875rem;margin:0;padding-right:25px;margin-bottom:var(--p1h47fyc-1);white-space-collapse:preserve-breaks;}
.Division_d1ak8bxk{font-size:0.875rem;margin:0;}
.TickCircle_tt7nmzw{padding-right:4px;height:38px;width:38px;}
.ThreeBalls_tqhb0op{max-height:38px;max-width:38px;position:absolute;top:0;left:-40px;}
.SourcesFetchedSuccessIcon_sjk571m{position:absolute;top:2px;left:-34px;color:#1fccc0;}
.SourcesFetchedErrorIcon_sztsvly{position:absolute;top:2px;left:-34px;color:#e51010;}
.ParagraphContainer_p1h29re8{position:relative;}
.ErrorFetchingSourcesMessage_ejr93e6{color:#e51010;font-size:0.75rem;}
