.ViewContainer_v1ihy8pa{padding-left:8vw;padding-right:8vw;-webkit-box-flex:1;-webkit-flex-grow:1;-ms-flex-positive:1;flex-grow:1;}
.ViewInnerContainer_v1qjbj2e{height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.PaddingElement_p1a226dy{height:var(--p1a226dy-0);}
.StageContainer_sh6sm0m{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;justify-items:flex-start;-webkit-flex:0.93;-ms-flex:0.93;flex:0.93;}
.PopoverAnchor_p7cnwxy{position:absolute;right:10%;top:17%;}
.VersionNumber_v1ll8xj1{color:#ffffff;}
.AdvancedSearchOption_a19261dy{padding-bottom:30px;}.AdvancedSearchOption_a19261dy p{color:#ffffff;margin:0;display:inline;}.AdvancedSearchOption_a19261dy button{display:inline;}
.StatusNotification_s1lp64ic{width:1000px;font-size:1rem;margin-top:0;padding-bottom:25px !important;padding-right:34px;padding-left:34px;background-color:#355672;}
.NotificationOkButton_n21nzc7{background-color:var(--n21nzc7-0) !important;color:var(--n21nzc7-1) !important;font-size:1rem !important;margin-top:16px;margin-bottom:4px;min-width:0 !important;min-height:0 !important;width:60px;height:28px;}.NotificationOkButton_n21nzc7:hover,.NotificationOkButton_n21nzc7:focus{box-shadow:0 0 0 4px var(--n21nzc7-3) !important;}
.NotificationText_n9qtc3i{max-height:145px;overflow-y:auto;white-space:pre-wrap;}
.NotificationContainer_n1mmiclf{position:absolute;bottom:5%;z-index:1;}
.AdvancedSearchTogglePaddingElement_a1mmer2m{height:54px;width:1px;}
.PoweredBy_p1euqtbg{position:absolute;bottom:2rem;left:1.5rem;}
.notificationDescriptionClass_n1gztr41{padding-top:0;margin-top:10px;}
