.report-section-content-inner {
  background-color: $grey_panel;
  padding: 16px 16px 16px 16px;
  height: 100%;

  .overflow-hidden {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div:not(.report-flow):not(.report-table-row):not(.report-image-gallery):not(
      .di-source-card
    ) {
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
  }
  > div > img {
    align-self: flex-start;
  }
  // .sourceCardTag.companyStatusActive{
  //   color: $grey_default;
  // }
  // dl{
  //   line-height: 1;
  //   dt {
  //     color: $grey_mid;
  //     padding-bottom: 0;
  //     margin-bottom: 12px;
  //     font-weight: normal;
  //   }
  //   dd{
  //     margin-bottom: 12px;
  //     padding-bottom: 0;
  //   }
  // }
  dl {
    dt {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
.report-section-content-card {
  background-color: $grey_panel;
  padding: 8px 8px;
  border: 2px;
  border-radius: 1px;
}
.sourcingImage {
  max-height: 60px;
  max-width: 80px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5px;
  object-fit: cover;
}
.sourcingImageBorder {
  float: right;
  margin-left: 20px;
  display: flex;
  align-items: center;
  /*background-color: $grey_light;*/
  object-fit: cover;
  height: 60px;
  width: 80px;
}

smallRisk {
  background-color: $red_mid;
  color: $white;
  font-size: 70%;
  border-radius: 5px;
  top: 0;
  padding: 4px 8px;
  opacity: 0.8;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

smallbusinessrisk {
  background-color: $red_mid;
  color: $white;
  font-size: 70%;
  border-radius: 5px;
  top: 0;
  padding: 4px 8px;
  opacity: 0.8;
}

smallpositive {
  background-color: $green_light;
  color: $white;
  font-size: 90%;
  border-radius: 5px;
  top: 0;
  padding: 4px 8px;
}

highlightrisk {
  color: $red_dark;
}

highlightpositive {
  color: $green_light;
  font-weight: bolder;
}
