.report-print-header {
  display: none;
}

.top-sticky-container {
  margin-left: -3px;
  z-index: $z-index-l8;
  width: 100%;
  max-width: 1030px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
}

.report-header {
  display: flex;
  justify-content: space-between;
  font-family: $Inter_Regular;
  font-size: $font_size_md;
  width: 100%;
  max-width: 1024px;
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;

  .report-header-top-sticky {
    background-color: $white;
    height: 0;
    transition: opacity 0.5s, ease-in-out;
    overflow-y: visible;
    width: 100%;
    max-width: 992px;
    opacity: 0;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 1080px;

    &--active {
      display: flex;
      transition: opacity 0.3s ease-out;
      height: 55px;

      .full-text {
        top: 30px;
        min-height: 60px;

        &.export-to-pdf {
          min-height: 26px;
        }
      }

      .top-section {
        margin-top: 0 !important;
        padding: 0 3px;
      }
      opacity: 1;
      overflow-y: visible;

      .report-details {
        display: none;
      }

      &.showDetails {
        height: 90px;

        .report-details {
          display: flex;
          overflow-y: hidden;
        }
      }
    }
  }

  .report-title {
    font-size: $font_size_2xl;
    font-family: $InterDisplay;
    letter-spacing: $heading_letter_spacing_2xl;
    flex-grow: 1;
  }

  ul {
    &.report-menu-items {
      display: flex;
      flex-direction: row;
      width: auto;
      margin-right: 0;
      margin-bottom: 0;
      padding-left: 0;

      .full-text {
        bottom: 30px;
        width: 112px;
      }

      li.nav-item {
        width: auto;
        height: 28px;
        margin-left: 6px;
        list-style-type: none;
      }
    }
  }

  .header-top-information {
    width: 100%;

    .top-section {
      justify-content: space-between;
      display: flex;
      align-items: center;
      height: 54px;

      /* WARNING!!
         changing this should mean changing the trigger point for the report sticky behaviour (in JS)
      */
      margin-top: 30px;

      .left {
        padding-left: 13px;
        flex-grow: 1;
        width: 60%;

        .title {
          align-items: center;
          opacity: 1;
          transition: opacity 0.5s;
        }
      }

      &.hide-menu {
        .title {
          opacity: 0;
        }
      }

      .right {
        display: flex;
        align-items: center;
        positon: relative;
      }
    }

    .logo-header {
      width: 28px;
      height: 28px;
    }
    .report-details {
      width: 100%;
      color: $grey_mid;
      font-size: $font_size_sm;
      padding-left: 16px;

      .feature-switches {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      ul {
        display: flex;
        flex-direction: row;
        width: auto;
        margin-bottom: 0;
        padding-left: 0;
        align-items: center;

        li {
          width: auto;
          list-style: none;
        }

        .navigation-bar-icon {
          width: 32px;
          height: 32px;
        }
      }

      ul {
        li {
          padding-left: 10px;
          margin-left: 10px;

          &:first-child {
            padding-left: 0;
            margin-left: 0;
            border-left: 0;
          }

          border-left: 1px solid $grey_lighter;
        }
      }
    }
  }

  .tooltip-bottom {
    display: inline-block;
  }
  .report-header-status {
    text-transform: uppercase;
    background-color: $report_header_background;
    color: $white;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 8px;
    border-radius: 3px;
    height: 22px;
    font-size: $font_size_md;
  }
  .report-header-author {
    padding-left: 64px;
  }
}

@media print {
  .report-header {
    display: none;
  }
  .report-print-header {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    color: $grey_dark;
    padding: 10px;
  }
  .report-detail-keys {
    margin-right: 2rem;
  }
  .report-detail-values {
    flex-grow: 1;
  }
}

.report-header__logo {
  height: 32px;
  min-width: 105px;
  text-decoration: none;
}
