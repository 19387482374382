.ManageOrganisation_m1fzsxg{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding:1rem;height:90vh;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.ManageOrganisationInnerContainer_m5o93ez{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;}
.CustomTitleCard_c14e1ca6{background-color:#243c59;}.CustomTitleCard_c14e1ca6 h5 + div{color:#ffffff;}
.OrgDetails_ox5jml7{margin:0;color:#d2d3d8;margin-left:3.75rem;}
.InputGroup_ix1r7mv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.UsagePanel_u1o3alav{background-color:#243c59;margin-top:0.75rem;}
.Controls_c43g9y9{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-self:flex-end;-ms-flex-item-align:end;align-self:flex-end;width:100%;}
.Button_b1vls4xm{-webkit-flex:1;-ms-flex:1;flex:1;}
.LeftControls_lrl5ta0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1.25rem;}
.ErrorFallback_e7rtssg{height:90vh;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding-bottom:12.5rem;color:#b3b5bd;font-size:1.5rem;gap:1rem;}
.BuildingIcon_bl0cx24{-webkit-transform:scale(0.875);-ms-transform:scale(0.875);transform:scale(0.875);}
.Text_t1pnvodv{color:#b3b5bd;font-size:0.875rem;line-height:1.375rem;margin:0;}
.ModalContent_miwr0en{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;max-height:34.375rem;overflow:auto;}
.LoadingBar_le4s3p8{position:absolute;top:-1px;left:-1px;width:104%;height:104%;border-radius:50%;border:4px solid #232947;border-left-color:#28e4fa;-webkit-animation:rotate-LoadingBar_le4s3p8 1.5s linear infinite;animation:rotate-LoadingBar_le4s3p8 1.5s linear infinite;}@-webkit-keyframes rotate-LoadingBar_le4s3p8{0%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);}100%{-webkit-transform:rotate(360deg);-ms-transform:rotate(360deg);transform:rotate(360deg);}}@keyframes rotate-LoadingBar_le4s3p8{0%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg);}100%{-webkit-transform:rotate(360deg);-ms-transform:rotate(360deg);transform:rotate(360deg);}}
.AvatarIconContainer_atfy14a{position:relative;border-radius:50%;}
.MonitoringModalSectionHeader_mhf7oyw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.HeaderWrapper_h1wwdji4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:12px;}
.TextWrapper_tkwjivx{color:#b3b5bd;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:4px;}
