.InsightsChat_i16ajy43{width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.5rem;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;position:relative;}
.ChatDescription_c1dkjb5z{font-size:0.875rem;margin:0;}
.ChatAndInputSection_c1bdvq9l{position:relative;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-flex:1;-ms-flex:1;flex:1;}
.ChatSection_czbshqu{overflow:auto;-webkit-flex:1;-ms-flex:1;flex:1;padding-bottom:1rem;}

.SendButton_szz93zm{all:unset;border-radius:50%;background:#7439B3;color:#ffffff;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:38px;height:38px;cursor:pointer;}.SendButton_szz93zm:not(:focus-visible,:hover){outline:none;}.SendButton_szz93zm:disabled{outline:none;cursor:not-allowed;}
.Alert_ao2kny8{stroke:#f14b4b;margin-top:0.25rem;}
.HeaderContainer_hk1s40a{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:0.25em;}
.TooltipContent_t77caq4{margin-bottom:0;padding:0.875rem;font-size:0.875rem;}
.SuggestedQuestions_s97clel{position:absolute;background-color:#f2f4f5;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:16px;bottom:97px;padding-top:36px;}
.Suggestion_s18oxgqo{all:unset;width:310px;box-sizing:border-box;padding:12px;color:#7439B3;background-color:#EBE5F2;font-size:0.875rem;-webkit-transition:background-color 0.3s;transition:background-color 0.3s;border-radius:100px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;cursor:pointer;}.Suggestion_s18oxgqo:not(:focus-visible){outline:none;}.Suggestion_s18oxgqo:hover,.Suggestion_s18oxgqo:focus{color:#7439B3;background-color:#CEB5E9;}
.AskInputContainer_a1s3ar2f{padding:1rem 0;height:97px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.AskInputContainer_a1s3ar2f .MuiInputBase-root{border-radius:40px;box-shadow:0px 0px 4px 0px #00000040 inset;padding-right:4px;font-size:0.875rem;}.AskInputContainer_a1s3ar2f .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,.AskInputContainer_a1s3ar2f .MuiInputBase-root:focus .MuiOutlinedInput-notchedOutline,.AskInputContainer_a1s3ar2f .MuiInputBase-root:active .MuiOutlinedInput-notchedOutline,.AskInputContainer_a1s3ar2f .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline{border:1px solid #7439B3;box-shadow:0px 0px 6px 0px #7439B3;outline:none;}.AskInputContainer_a1s3ar2f .MuiInputBase-root .MuiOutlinedInput-notchedOutline{outline:none;}.AskInputContainer_a1s3ar2f .MuiInputBase-root input{outline:none;padding:12px 20px;}
.Heading_h9py9hs{font-size:1.125rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.CloseButton_c16jlau8{border:0;position:absolute;top:0;right:0;background:#ffffff;padding:0.5rem;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.CloseButton_c16jlau8:hover{background:#d2d3d8;}

