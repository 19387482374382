/*****************************
TODO:
- refactor componets to convert from css to scss
- remove styled components
- remove styles - are we using bootstrap? if so use bootstrap definitions where possible 
- there are many hardcoded colors and font sizes in components for charts etc - deal with these

*****************************/

@import "colors";
@import "fonts";
@import "headings";
@import "./global";

@import "./components";

// We don't want to apply custom scrollbars to the actual
// body as we end up with a grey gutter that we can't hide.
body {
  * {
    ::-webkit-scrollbar {
      width: 15px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #d2d3d8;
      border: 4px solid rgba(0, 0, 0, 0);
      border-radius: 999px;
      min-height: 50px;
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-track {
      display: none;
    }
  }
}
