.NoData_n1aiic64{font-style:italic;color:#b3b5bd;font-size:0.875rem;}
.QuoteSuperscript_q1ryc3gu{top:-2px;}
.QuoteContainer_q1x264a{font-size:0.875rem;}
.Quote_q1k3fqdx{font-style:italic;}
.RiskSummaryTitle_r1mv6n3e{font-size:1rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;max-width:473px;margin-top:var(--r1mv6n3e-2);font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.RiskCategories_r3lk3vl{margin-top:8px;font-size:0.875rem;}
.RiskSummaryDate_rl8x1r4{font-size:0.875rem;margin-bottom:5px;color:#858996;}
.SectionContainer_squ7mad{border:1px solid #ffffff;border-left:0;border-top:0;padding:16px;background-color:#f2f4f5;page-break-inside:avoid;}
.SectionHeader_s11rygyf{margin-bottom:10px;font-size:1.125rem;color:#474952;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.SanctionReason_sfafz7r{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.List_l18tgkzs{list-style-type:none;margin:0 0 0 10px;padding-left:0;}
.SanctionedStatement_spyxdb4{font-size:0.875rem;}
.Dates_d1wxy18h{display:inline-block;font-size:0.875rem;color:#858996;}
.MonetaryValue_m1atvp7k{font-size:0.875rem;}
.SanctionItem_s13560wr{padding-bottom:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.ReferenceSuperscript_r1v9nppc{color:#858996;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;padding-right:5px;}
.RiskCategoryPill_r1u05rjt{padding:0px 8px;border:1px solid #858996;border-radius:16px;font-size:0.875rem;height:21px;text-transform:capitalize;color:#858996;margin:0 4px 4px 0;}
.RiskFlags_r1fzykgn{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.Content_c1fq25oh{background-color:#f2f4f5;}
.SanctionSource_s1r51vhm{font-size:0.875rem;}
.SanctionDates_sgt3dqy{font-size:0.875rem;color:#858996;}

.SanctionsFooter_s47n4n4{font-size:0.875rem;padding-top:10px;color:#858996;}
.Info_i1iciowv{height:38px;background-color:#f2f4f5;padding:8px 16px;font-size:0.875rem;color:#8e70b8;}
.Truncate_t1wt14kj{display:inline;}
.quoteListItem_q19tiq8w{padding-bottom:8px;}
.listItem_l3sobfq{padding-bottom:8px;}
.sourceSuperscript_sbu86xq:after{content:"" !important;}
.listOfSummaries_l1x6twtw > ul{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.masonryGrid_m7yagye{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:auto;}
.masonryColumn_mlw1fjo{background-clip:padding-box;}.masonryColumn_mlw1fjo:not(:last-child){border-right:2px solid white;}.masonryColumn_mlw1fjo > div{border-top:2px solid white !important;border-left:none !important;border-right:none !important;border-bottom:none !important;}
