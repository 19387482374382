.ManageOrganisation_m1lsr1m4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding:1rem;gap:1rem;height:calc(100vh - 85px);-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.ManageOrganisationInnerContainer_m1km7sg3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;}
.CustomTitleCard_cnb4nug{background-color:#243c59;}.CustomTitleCard_cnb4nug h5 + div{color:#ffffff;}
.OrgDetails_o1xtdgll{margin:0;color:#d2d3d8;margin-left:3.75rem;}
.InputGroup_i197gvpw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.UsagePanel_u13pcyum{background-color:#243c59;margin-top:0.75rem;}
.Controls_c15rii9f{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-self:flex-end;-ms-flex-item-align:end;align-self:flex-end;width:100%;}
.Button_b171gnp3{min-width:12.5rem;}
.LeftControls_l6o13nd{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1.25rem;}
.ErrorFallback_e1o6jydw{height:90vh;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;padding-bottom:12.5rem;color:#b3b5bd;font-size:1.5rem;gap:1rem;}
.BuildingIcon_bxs04it{-webkit-transform:scale(0.875);-ms-transform:scale(0.875);transform:scale(0.875);}
.FeatureSection_f1dr049t{background-color:#243c59;border-radius:0.75rem;padding:0.75rem 1rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.FeatureSectionItem_f6xdlv8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.FeatureSectionDetails_f6fdzn0{-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;}
.FeatureSectionAction_fc8xom1{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.FeatureSectionDescription_f1nsjg8v{color:#b3b5bd;}
