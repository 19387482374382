.EditorContainer_e1jsgyo1 .remirror-editor-wrapper{padding:0;max-height:500px;overflow:auto;border-width:1px;border-style:solid;border-color:var(--e1jsgyo1-0);border-radius:4px;color:#474952;-webkit-transition:background-color 0.3s ease 0s;transition:background-color 0.3s ease 0s;}.EditorContainer_e1jsgyo1 .remirror-editor-wrapper:hover{background-color:var(--e1jsgyo1-2);}.EditorContainer_e1jsgyo1 .remirror-editor-wrapper a{color:#7963d2;}.EditorContainer_e1jsgyo1 .remirror-editor-wrapper a:hover{-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer;}.EditorContainer_e1jsgyo1 .ProseMirror{border:none;box-shadow:none;}.EditorContainer_e1jsgyo1 .ProseMirror:focus,.EditorContainer_e1jsgyo1 .ProseMirror:active{box-shadow:var(--e1jsgyo1-3);}
.Toolbar_t1nxxf32{padding-bottom:10px;}
.ToolbarButton_t1qtyjsj{outline:none;border-radius:6px;background-color:var(--t1qtyjsj-0);border:none;-webkit-transition:background-color 0.2s;transition:background-color 0.2s;margin-right:2px;}.ToolbarButton_t1qtyjsj:hover,.ToolbarButton_t1qtyjsj:focus,.ToolbarButton_t1qtyjsj:active{background-color:rgba(0,0,0,0.1);outline:none;}
.Actions_ab8ndh6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-webkit-justify-content:flex-end;-ms-flex-pack:end;justify-content:flex-end;gap:10px;padding-top:10px;}
.SaveButton_st7vja2{color:#ffffff !important;min-height:0 !important;height:28px;margin-left:8px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.CancelButton_c14t6ao7{color:var(--c14t6ao7-0) !important;min-height:0 !important;height:28px;}
.ColorPicker_cap18tr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:8px;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;gap:8px;width:120px;}
.ColorOption_cbzkb5i{background-color:var(--cbzkb5i-0);height:20px;width:20px;border-radius:4px;border:none;outline:none;}
.UnsavedChanges_u1v2zd2m{z-index:100;color:#858996;font-size:0.875rem;margin-left:8px;}
.Spinner_s195yuv6{width:21px;height:21px;border-width:2px;}
.ActionButtons_a1dg101b{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
