.DropdownButton_dgkeg0w{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;gap:4px;}
.DropdownButtonText_d11x1iyp:hover{color:#6a4394;}
.Dropdown_d8u1nxw{width:336px;}
.DropdownOption_dbixanq{padding:8px;cursor:pointer;}.DropdownOption_dbixanq:not(:last-child){border-bottom:1px solid #f2f4f5;}.DropdownOption_dbixanq:hover{background-color:var(--dbixanq-1);}
.Tooltip_tsx6h8z{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.ControlButton_c1dlm9rq{background:#f2f4f5;padding:6px 12px 6px 8px;border-radius:100px;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}.ControlButton_c1dlm9rq:hover{background:#EBE5F2;}.ControlButton_c1dlm9rq:hover span{color:#6a4394;}
.ButtonIcon_b13ukdj8{background:var(--b13ukdj8-0);color:var(--b13ukdj8-1);border-radius:50%;min-width:18px;min-height:18px;max-width:18px;max-height:18px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding:2px;}.ButtonIcon_b13ukdj8 svg{max-width:100%;max-height:100%;}
.DiscardModalContainer_dqbdxyq{z-index:7;position:absolute;top:0;left:0;width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:rgba(255,255,255,0.7);}
.DiscardModal_d1h1hzrw{position:relative;background:#ffffff;padding:40px 40px 20px 40px;border-radius:10px;box-shadow:0px 0px 12px 0px rgba(0,0,0,0.4);display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;text-align:center;gap:5px;}.DiscardModal_d1h1hzrw p{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.DiscardModalHeading_dievjze{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.DiscardModalActions_d186952y{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:5px;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;padding-top:10px;}
.CancelButton_cpx2pql{background:#f2f4f5;}.CancelButton_cpx2pql:hover{background:#d2d3d8;color:#232947;}
.ConfirmButton_c1ya5wpw{background:#7439B3;color:#ffffff;}.ConfirmButton_c1ya5wpw:hover{background:#542487;}
.CloseButton_cjtjw12{position:absolute;top:10px;right:10px;background:#f2f4f5;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;width:22px;height:22px;}.CloseButton_cjtjw12 svg{width:15px;height:15px;}
.DiscardedCover_dn2yivn{z-index:7;position:absolute;top:0;left:0;width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;background:rgba(255,255,255,0.7);}
.DiscardedIcon_d1mjn0wc{background:#ffffff;border:1px solid #7439B3;color:#7439B3;border-radius:50%;min-width:32px;min-height:32px;max-width:32px;max-height:32px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}.DiscardedIcon_d1mjn0wc svg{max-width:100%;max-height:100%;}
.DiscardedCoverHeading_dxz9ali{color:#7439B3;}
.UndoButton_u1um2ial{background:#f2f4f5;}.UndoButton_u1um2ial:hover{background:#d2d3d8;color:#232947;}
