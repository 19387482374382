.CardContent_c1yvfvj8{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;}
.CardTopSection_cufg3h6{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}
.DescriptionSection_dzrkpwz{margin-top:8px;font-size:0.875rem;color:#474952;word-break:break-word;}
.SocialMediaWithInspector_s61705d{padding-top:6px;}
.SocialMediaIconsContainer_sfhkee6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;pointer-events:none;}
.CardSummary_cdc6mdv{color:#858996;}
.NameField_n1w3pgsw{font-size:1rem;font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;color:#474952;max-height:24px;}
.DurationPills_d1imh1rv{text-align:left;line-height:1.25;padding:4px 0;}
.NoDate_n15kxz6l{color:#858996;}
.RoleDuration_r157byqc{color:var(--r157byqc-0) !important;font-size:0.875rem;}.RoleDuration_r157byqc .NoDate_n15kxz6l{color:var(--r157byqc-3);}
.PillsContainer_pho05a5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.RolePill_rl51cm9{padding:0px 8px;border:1px solid #b3b5bd;border-radius:16px;margin-right:5px;-webkit-transition:box-shadow 0.2s;transition:box-shadow 0.2s;font-size:0.875rem;text-transform:capitalize;opacity:0.6;border-color:var(--rl51cm9-2);color:var(--rl51cm9-2);}.RolePill_rl51cm9:last-child{margin-right:0;}
.ListItem_l1udvc1g{margin-bottom:8px;padding:0;}.ListItem_l1udvc1g:first-child{margin-top:8px;}.ListItem_l1udvc1g:last-child{margin-bottom:6px;}
.RoleTitle_rifd3pa{font-size:0.875rem;color:#474952;line-height:20px;}
.RoleDate_rq8jjva{font-size:0.875rem;}
.Rule_r1s0y83r{color:#d2d3d8;margin:0;margin:8px 0;}
.Info_i19stvta{font-size:0.875rem;color:#858996;white-space:nowrap;}
.RolesSinceStartLabel_r1tegp9d{font-size:0.875rem;color:#858996;white-space:nowrap;}
.OnlineProfilesInfo_o1b7iylz.Info_i19stvta{color:#00a8e5;margin-top:3px;}
.PillsInfo_pfikufw.Info_i19stvta{color:#858996;}
.LocationText_l142vxez{font-size:0.875rem;color:#858996;word-break:break-word;text-align:left;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.LocationName_l1m5mjs6{max-width:180px;display:inline-block;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;padding-right:5px;}
.DescriptionContainer_d1vbblbn{margin-top:-0.5px;}
.MissingData_m1pv2yoo{font-size:0.875rem;font-style:italic;margin:0;}
.NoActiveRoles_n1v03hzq{font-size:0.875rem;color:#b3b5bd;}
.StyledWithInspector_s9ur7wy{margin-top:8px;}
.InspectorSocialMediaIcons_i1c5gsws{padding:2px 0 3px 0;}.InspectorSocialMediaIcons_i1c5gsws a{color:var(--i1c5gsws-0) !important;}.InspectorSocialMediaIcons_i1c5gsws path{fill:var(--i1c5gsws-1) !important;}
.RoleContainer_r1ryhgi8{padding-bottom:6px;}
.MediaHandlerContainer_mtjk38a{padding-bottom:6px;}

.ContentContainer_c1iamld0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;width:100%;}.ContentContainer_c1iamld0 .RoleTitle_rifd3pa,.ContentContainer_c1iamld0 .NameField_n1w3pgsw,.ContentContainer_c1iamld0 .DescriptionSection_dzrkpwz{color:var(--c1iamld0-3);}.ContentContainer_c1iamld0 .PillsInfo_pfikufw,.ContentContainer_c1iamld0 .LatestRoleDuration_lo7d1vb,.ContentContainer_c1iamld0 .RolesSinceStartLabel_r1tegp9d,.ContentContainer_c1iamld0 .LocationText_l142vxez,.ContentContainer_c1iamld0 .MissingData_m1pv2yoo{color:var(--c1iamld0-9) !important;}.ContentContainer_c1iamld0 .SocialMediaIconsContainer_sfhkee6{-webkit-filter:var(--c1iamld0-11);filter:var(--c1iamld0-11);opacity:var(--c1iamld0-12);}
.pillClassName_pn0usiw{border-radius:16px;padding:3px 10px;}
