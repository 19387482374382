.SlideContainer_s19jmwhu{background:transparent;overflow:visible;}
.BoxContent_bgtao93{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;border-radius:16px;background:#ffffff;z-index:2;padding:28px;width:400px;height:266px;position:absolute;top:var(--bgtao93-1);left:var(--bgtao93-2);}
.Carousel_crhmgz8{margin-top:auto;}.Carousel_crhmgz8 .slide{background:transparent;}.Carousel_crhmgz8 .control-dots{position:relative;padding-top:1rem;}.Carousel_crhmgz8 .control-dots .dot{background:#6b6b6b;box-shadow:none;}.Carousel_crhmgz8 .control-dots .dot.selected,.Carousel_crhmgz8 .control-dots .dot:has(~ .selected){opacity:1;background:#ffffff;}
.CarouselContainer_c1frm2uc{height:90vh;max-height:50%;width:60%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;border-radius:12px;}
.CarouselImage_choyl2q{top:var(--choyl2q-0);left:var(--choyl2q-1);min-height:280px;max-width:80%;max-height:80%;-webkit-align-self:center;-ms-flex-item-align:center;align-self:center;position:relative;}
.LinkToVideo_lkohy0n{color:#02a3d5;text-align:center;font-size:1rem;font-style:normal;font-weight:700;line-height:28px;-webkit-letter-spacing:-0.69px;-moz-letter-spacing:-0.69px;-ms-letter-spacing:-0.69px;letter-spacing:-0.69px;-webkit-text-decoration-line:underline;text-decoration-line:underline;}
.Heading_h1g41igg{color:#000000;font-size:1.125rem;}
.Divider_d1yanb4f{color:#000000;width:10%;height:2px;border-top-color:#000000;text-align:left;}
.Description_d1bsx4hz{font-size:1rem;color:#424242;font-weight:500;text-align:left;}
.ButtonWrapper_b1ivwwld{background:transparent;color:#ffffff;border:none;}
.NextArrow_naq6kit{background:transparent;color:#ffffff;-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);}
.PreviousArrow_p1bj8iq0{background:transparent;color:#ffffff;}
.ActionButtonContainer_aszk8jd{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;margin-top:auto;gap:24px;}
