.TableHeading_tjtatbn{padding-block:9px 0;text-align:left;color:#f14b4b;font-size:1rem;border-bottom:1px solid var(--tjtatbn-2);}
.ExpandHeaderCountLabel_e2wu0mb{color:#474952;}
.Chevron_cpj4mha{width:10px;-webkit-transform:var(--cpj4mha-0);-ms-transform:var(--cpj4mha-0);transform:var(--cpj4mha-0);}.Chevron_cpj4mha path{fill:#474952;}
.ConfidenceHitCount_c13m5vwq{color:#474952;font-size:1.125rem;margin-right:8px;}
.ExpandHeaderRightContent_e1hh8i3z{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;opacity:var(--e1hh8i3z-0);}
.InfoIconContainer_i1o76yvh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:8px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.DisabledInfoIconContainer_dqr8ody.InfoIconContainer_i1o76yvh{opacity:0.2;}
.ScreeningTableWrapper_s172she3{padding:0 16px;padding-bottom:var(--s172she3-0);}
.ScreeningTable_s1m5vyzd{width:100%;}
.ExpandHeader_ervujti{all:unset;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:0 16px;font-size:1rem;color:#858996;height:48px;background-color:#dfe1e3;cursor:var(--ervujti-3) !important;}.ExpandHeader_ervujti .ConfidenceHitCount_c13m5vwq,.ExpandHeader_ervujti .Chevron_cpj4mha{color:var(--ervujti-6);}.ExpandHeader_ervujti .ConfidenceHitCount_c13m5vwq path,.ExpandHeader_ervujti .Chevron_cpj4mha path{fill:var(--ervujti-6);}.ExpandHeader_ervujti:not(:focus-visible){outline:none;}
