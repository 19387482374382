.InputPreview_iog8i5s{list-style-type:none;height:28px;margin-bottom:4px;background-color:var(--iog8i5s-0);cursor:pointer;}.InputPreview_iog8i5s:focus{background-color:rgba(40,228,250,0.3);outline:none;}
.Menu_m10ovzrk{z-index:9;position:absolute;width:387px;background-color:#4f94b4;padding:12px 0;margin-top:12px;border-radius:6px;visibility:var(--m10ovzrk-1);}
.MenuItem_mcd55qr{cursor:pointer;background-color:var(--mcd55qr-0);list-style-type:none;line-height:28px;height:28px;padding-left:12px;}.MenuItem_mcd55qr:hover,.MenuItem_mcd55qr:focus{background-color:rgba(40,228,250,0.3);outline:none;}
.ComboBox_ce3ktk5{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:387px;height:36px;border-radius:18px;border:1px solid #ffffff;box-shadow:0 0 0 4px var(--ce3ktk5-1);opacity:var(--ce3ktk5-2);}
.Input_i1lg09sw{border:none;height:36px;border-radius:18px;font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;box-shadow:none;width:100%;margin:0;padding:5px 0 7px 13px;font-size:1rem;line-height:1.5;background-color:transparent;background-clip:padding-box;-webkit-transition:border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;transition:border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;}.Input_i1lg09sw::-webkit-input-placeholder{opacity:0.6;}.Input_i1lg09sw::-moz-placeholder{opacity:0.6;}.Input_i1lg09sw:-ms-input-placeholder{opacity:0.6;}.Input_i1lg09sw::placeholder{opacity:0.6;}.Input_i1lg09sw:focus{outline:0;}
.DropdownButton_daxf59h{all:unset;border-radius:18px;width:36px;}.DropdownButton_daxf59h:focus{box-shadow:0 0 0 4px var(--daxf59h-0) !important;outline:none;}
.ArrowDown_a39f9qf{margin-left:11px;}.ArrowDown_a39f9qf path{fill:#ffffff;}
.Info_ia6ryvd{opacity:0.7;}
.MenuItemsContainer_m177vf80{margin-top:4px;max-height:140px;overflow:auto;}
.InputPreviewText_if1z409{line-height:28px;margin-left:12px;padding-bottom:5px;}
.Rule_r10s9wlw{border-bottom:1px solid rgba(242,244,245,0.4);margin-left:12px;}
