.Menu_mo17j9e{overflow-y:scroll;max-height:190px;}
.ModalContent_m1ro9ton{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;max-height:34.375rem;overflow:auto;}
.Text_t1plyagp{color:#b3b5bd;font-size:0.875rem;line-height:1.375rem;margin:0;}
.CustomButton_c3jtqtt{-webkit-align-self:flex-start;-ms-flex-item-align:start;align-self:flex-start;}
.Form_f1yv5jz0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.FieldContainer_f1oem8w4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;max-height:4.8125rem;}
.Field_fj1o3p6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border-radius:6px;background:#f2f4f5;font-style:normal;font-weight:400;font-size:1rem;line-height:24px;color:#858996;padding:12px;}
.FieldLabel_flr8k35{color:#ffffff;font-size:0.875rem;}
.ResetPassword_rqquhem{all:unset;color:#00a8e5;font-size:0.875rem;cursor:pointer;}.ResetPassword_rqquhem:hover{-webkit-text-decoration:underline;text-decoration:underline;}.ResetPassword_rqquhem:not(:focus-visible){outline:none;}
.ResetPasswordText_r1uxxgg3{margin:0;color:#b3b5bd;font-size:0.875rem;}
.ResetPasswordSentText_ryjduqx.ResetPasswordText_r1uxxgg3{color:#28effa;}
.OptionsButton_o1om82na{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:0.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;font-size:0.875rem !important;line-height:1.125rem;all:unset;white-space:nowrap;cursor:pointer;}.OptionsButton_o1om82na:not(:focus-visible){outline:none;}
