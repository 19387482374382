.ConfidenceType_c10rz1u{color:#474952;text-transform:capitalize;}
.ConfidenceLabel_csbmge9{text-transform:capitalize;color:#474952;cursor:pointer;}
.ConfidenceRadioInputContainer_c1f4a9vo{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;font-size:0.875rem;color:#858996;cursor:pointer;}.ConfidenceRadioInputContainer_c1f4a9vo + .ConfidenceRadioInputContainer_c1f4a9vo{margin-top:3px;}
.SelectionPrompt_srqaj10{margin-bottom:14px;color:#858996;font-size:0.875rem;}
.DropdownToggle_d5iupb3{min-width:0 !important;min-height:0 !important;width:26px;height:26px;border-radius:50%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;border:none !important;background-color:var(--d5iupb3-0) !important;-webkit-transition:background-color 0.4s;transition:background-color 0.4s;box-shadow:none !important;}.DropdownToggle_d5iupb3:hover,.DropdownToggle_d5iupb3:focus{background-color:var(--d5iupb3-1) !important;}.DropdownToggle_d5iupb3:hover > svg > circle,.DropdownToggle_d5iupb3:focus > svg > circle{fill:var(--d5iupb3-2);}
.TooltipContent_t8p14iy{padding:14px;max-width:410px;font-size:0.875rem;}
.FeatureInfo_f14bkqrd{padding:10px;font-size:0.875rem;}
.SupportLink_sk31i37{color:#00a8e5 !important;-webkit-text-decoration:underline !important;text-decoration:underline !important;}
.PromptText_p8pfcdq{font-size:0.875rem;color:#474952;}
.PromptSubtext_pnkdt14{font-size:0.875rem;color:#858996;margin-top:5px;}
.UserSetInfoText_uoybu9g{text-transform:none;}
.MenuButtonIcon_m1fielki{height:18px;}.MenuButtonIcon_m1fielki > circle{fill:var(--m1fielki-0);}
.TooltipExplainerText_t1qef68n{color:#858996;font-size:0.875rem;}
.PrimaryActionString_pwm98kf:first-letter{text-transform:capitalize;}
